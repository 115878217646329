import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const {REACT_APP_SERVER_URL}=process.env;
export const fetchTaskListDatas  = createAsyncThunk(
  "filters/fetchTaskListDatas ",
  async (params) => {
    const { data } = await axios.post(
      `${REACT_APP_SERVER_URL}/api/list-task`,
      {
        headers: {"Authorization" : `Bearer ${params?.token}`},
      }
    );

    return data.data;
  }
);

export const metaTasklistSlice= createSlice({
  name: "filtercustomer",
  initialState: {
    task_list: [],
    error: null,
  },
  
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskListDatas .pending, (state) => {
      })
      .addCase(fetchTaskListDatas .fulfilled, (state, action) => {
        state.task_list = action.payload.tasks.data;
          
      })
      .addCase(fetchTaskListDatas .rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase("persist/REHYDRATE", (state, action) => {
        // clear filter state here
        state.task_list = [];
        state.error = null;
      });
  },
});

export const {
  
} = metaTasklistSlice.actions;
export default metaTasklistSlice.reducer;