
import { createSlice } from '@reduxjs/toolkit';

const selectedIdsSlice = createSlice({
  name: 'selectedIds',
  initialState: [],
  reducers: {
    toggleId: (state, action) => {
      const clickedId = action.payload;
      if (state.includes(clickedId)) {
        return state.filter((id) => id !== clickedId);
      } else {
        return [...state, clickedId];
      }
    },
    clearSelectedIds: () => [],
  },
});

export const { toggleId, clearSelectedIds } = selectedIdsSlice.actions;


export default selectedIdsSlice.reducer;