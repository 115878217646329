import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const {REACT_APP_SERVER_URL}=process.env;
export const fetchDesignationList  = createAsyncThunk(
  "filters/fetchDesignationList ",
  async (params) => {

    const { data } = await axios.get(
      `${REACT_APP_SERVER_URL}/api/designation?page=${params.page?params.page:1}`,
      {
        headers: {"Authorization" : `Bearer ${params?.token}`},
      },
    );

    return data.data;
  }
);

export const usrdptFilterSlice= createSlice({
  name: "filtermeetup",
  initialState: {
    userData: [],
    error: null,
  },
  
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDesignationList .pending, (state) => {
      })
      .addCase(fetchDesignationList .fulfilled, (state, action) => {
        // state.userdpt_list = action.payload.data;
        // state.userlist = action.payload.data.user.data;
        // state.usercount = action.payload.data.user.pagination;
        // state.departmentlist = action.payload.data.departments.data;
        // state.departmentcount = action.payload.data.departments.pagination;
        state.designationlist = action.payload.data.designations.data;
        state.designationcount = action.payload.data.designations.pagination;
       
       
          
      })
      .addCase(fetchDesignationList .rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase("persist/REHYDRATE", (state, action) => {
        // clear filter state here
        state.userdpt_list = [];
        state.error = null;
      });
  },
});

export const {
  
} = usrdptFilterSlice.actions;
export default usrdptFilterSlice.reducer;