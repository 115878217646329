import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const {REACT_APP_SERVER_URL}=process.env;
export const fetchProfileDetails  = createAsyncThunk(
  "filters/fetchProfileDetails ",
  async (params) => {
    const { data } = await axios.post(
      `${REACT_APP_SERVER_URL}/api/user-profile`,
      {
        headers: {"Authorization" : `Bearer ${params?.token}`},
      }
    );
   
    return data.data;
  }
);

export const ProfileDetails= createSlice({
  name: "filtercustomer",
  initialState: {
    profiledetails: [],
    error: null,
    loading:true
  },
  
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileDetails .pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProfileDetails .fulfilled, (state, action) => {
        state.profiledetails = action.payload.user;
        state.loading = false;
      })
      .addCase(fetchProfileDetails .rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase("persist/REHYDRATE", (state, action) => {
        // clear filter state here
        state.profiledetails = [];
        state.error = null;
        state.loading = false;
      });
  },
});

export const {
  
} = ProfileDetails.actions;
export default ProfileDetails.reducer;