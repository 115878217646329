import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { store,persistor } from "./redux/Store";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";

// axios interceptor

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    const { requiresSecret } = config;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// axios interceptor response validation 

// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 401) {
//       // Redirect to login page or show login modal
//       window.location.href = "/login";
//       toast.error("NOT AUTHORIZED");
//     }
//     return Promise.reject(error);
//   }
// );

// ends

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
        
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
