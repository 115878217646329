import React, { useContext, useEffect, useState } from 'react'
import { NewContext } from '../../Pages/Tickets/TicketListing'
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { fetchCustomerDatas } from "../../redux/Customerlist";
import { setCustomerList } from "../../redux/Customerlist";

const { REACT_APP_AUTH_SERVER } = process.env;
const { REACT_APP_SERVER_URL } = process.env;
const UpdateCustomer = ({customer_list,updatecustomerClose}) => {
    const token = localStorage.getItem("token")
    const dispatch = useDispatch();
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        control,
        setValue,
        setFocus,
        reset,
        watch,
        setError,
        clearErrors,
        values,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const [cusid, setcusid] = useState()
    const [cus, setcus] = useState([])
    useEffect(() => {
        if(customer_list){
            setValue("cus", {
                name: customer_list ? customer_list['customer']?.name : '',
                email: customer_list ? customer_list['customer']?.email : '',
                contact: customer_list ? customer_list['customer']?.contact : ''
    
            })
        }
    }, [customer_list])

    const handleupdatecustomer = async () => {
        await axios({
            url: `${REACT_APP_SERVER_URL}/api/customer/${customer_list ? customer_list['customer']?.id : ""}`,
            method: 'PUT',
            headers: { "Authorization": `Bearer ${token}` },
            data: {
                name: watch("cus.name"),
                email: watch("cus.email")?watch("cus.email"):null,
                contact: watch("cus,contact"),
                country_code: "+91"
            }
        }).then((res) => {
            // dispatch(setCustomerList(res.data.data))
            if(res.data.success){

                dispatch(fetchCustomerDatas({id:customer_list['customer']?.id}))
                updatecustomerClose()           
                toast.success(res.data.message)
            }
            else{
                {res.data.data.contact?toast.error(res.data.data.contact[0]):<></>}
                {res.data.data.email?toast.error(res.data.data.email[0]):<></>}
            }

        }).catch((err) => {
            if (err.request) { console.log(err.request) } if (err.response) {
                console.log(err.response)

            }
        })
    }
    function preventOtherCharactersFromNumberInputcount(evt) {
        if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57 || evt.target.value.length == 10) {
            evt.preventDefault();
        }
    }
    return (
        <>
            <div className="modal-dialog modal-dialog-lg ticket-modal customer-modal modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <form className="add-ticket-form" onSubmit={handleSubmit(handleupdatecustomer)}>
                            <h3 className="text-center">Update basic info</h3>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label for="">Customer name <span>*</span></label>
                                        <input type="text" className="form-control" name='name'
                                            {...register("cus.name", {
                                                required: "Name is required",
                                            })}

                                            id="" aria-describedby="" placeholder="Enter customer name" />
                                        {errors?.cus?.name && <span className="error"> {errors.cus?.name.message}</span>}
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label for="">Contact number <span>*</span></label>
                                        <input  className="form-control"
                                            {...register("cus.contact", {
                                                required: "Contact number is required",
                                            })}
                                            onKeyPress={preventOtherCharactersFromNumberInputcount}
                                            type="text"
                                            maxLength={10}
                                            id="" aria-describedby="" placeholder="" />
                                        {errors?.cus?.contact && <span className="error"> {errors.cus?.contact.message}</span>}
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label for="">Email ID</label>
                                        <input type="email"
                                            {...register("cus.email")}
                                            className="form-control" id="" aria-describedby="" placeholder="" />
                                        {/* {errors?.cus?.email && <span className="error"> {errors.cus?.email.message}</span>} */}
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group text-center mt-4">
                                        <button type="submit" className="add-ticket">Save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateCustomer