import React, { useEffect, useState } from 'react';
import help from '../../assets/images/help.svg';
import logo from '../../assets/images/login-logo.svg';
import layout from '../../assets/images/layout-img.svg';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/style.css';
import '../../assets/css/resp.css';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import axios from 'axios';
import Input from 'react-phone-number-input/input'
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../Components/Loader/Loader';
const { REACT_APP_AUTH_SERVER}= process.env;
const {REACT_APP_SERVER_URL}=process.env;
function Signup() {
   
   const {
      register,
      formState: { errors, isValid, isDirty },
      handleSubmit,
      getValues,
      setValues,
      setFocus,
      values,
      reset,
      trigger,
  } = useForm({
      mode: "onChange",
      reValidateMode: "onChange",
  });
  const [mobile, setmobile] = useState()
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setloading] = useState(false);
  const handleCheckboxChange = (event) => {
   setIsChecked(event.target.checked);
 };
   const CreateUser =()=>{
      if(isChecked){
setloading(true)
         axios({
            url:`${REACT_APP_AUTH_SERVER}/api/register`,
            method:'POST',
            data:{
               name:getValues().name,
               email:getValues().email,
               // phone:getValues().mobile,
               phone:mobile,
               password:getValues().password,
               confirm_password:getValues().confirmpasswords,
               user_type:1
            }
         }).then((res)=>{
            
            if (res.data.success){

               Swal.fire({
                  icon: 'success',
                  text: res.data.message,
                  showConfirmButton: false,
                  timer: 2000
                })
                setloading(false)
               navigate('/Login')
            }
            else{
  
               toast.error(res.data.data.email[0])
               setloading(false)
            }
         }) .catch((err) => {
            
            if(err.request){
               
                console.log(err.request) } if(err.response){ 
               
                toast.error(err.response.data.data.email[0])
                setloading(false)
          }})
      }
      else{
         toast.error("Please Accept Terms and Conditions")
      }
   }
   function preventOtherCharactersFromNumberInputcount(evt) {
      if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57 || evt.target.value.length == 10) {
          evt.preventDefault();
      }
  }
    const navigate=useNavigate();
  return (
    <div className="login-page signup-page">
      <Loader loading={loading}/>
    <div className="container-fluid">
       <div className="row ">
          <div className="col-lg-5 col-md-5 col-sm-12 p-0">
             <div className="login-slider">
                <div className="login-logo">
                   <img src={logo} alt="Getlead"/>
                </div>
                <div className="layout-img">
                   <img src={layout} alt="Getlead"/>
                </div>
             </div>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12 p-0">
             <div className="login-form d-flex align-items-center justify-content-center">
                <div className="form-col">
                   <div className="help-text">
                      <a href="#"><span><img src={help} alt="Getlead"/> </span> Help center</a>
                   </div>
                   <form onSubmit={handleSubmit(CreateUser)}>
                      <div className="form-header">
                         <h2>Start closing more deals <br/>
                            with <span>Getlead</span> 
                         </h2>
                         <p>Start your 14-day free trial. No credit card required.</p>
                      </div>

                      <div className="form-group">
                         <label >Name</label>
                         <input type="text" 
                         
                         autoComplete="off"
                         {...register("name", {
                           required: true,
                           maxLength: {
                              value: 50,
                              message: "Name must be less than 50 characters."
                              },
                       })}
                         className="form-control"  
                         aria-describedby="emailHelp" 
                         placeholder="Enter name"/>
                          {errors.name?.type === "required" && (
                         <span className="error">Name is required</span>
                        )}
                         {errors.name && <span className="error">{errors.name.message}</span>}
                        
                      </div>
                      <div className="form-group">
                         <label >Email address</label>
                         <input type="email" 
                         {...register("email", {
                           required: true,
                           pattern:
                               /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              
                       })}
                       
                         className="form-control"  
                         aria-describedby="emailHelp" 
                         placeholder="Enter email"/>
                          {errors.email?.type === "required" && (
                         <span className="error">Email is required</span>
                        )}
                        {errors.email?.type === "pattern" && (
                        <span className="error">Email is not valid</span>
                        )}
                         {errors.email && <span className="error">{errors.email.message}</span>}
                      </div>
                      <div className="form-group">
                         <label >Password</label>
                         <input type="password" 
                         {...register("password", {
                           //   required: true,

                           required: "Password is required",
                           minLength: {
                               value: 7,
                               message: "Password must have at least 7 characters."
                           },
                           maxLength: {
                              value: 15,
                              message: "Password must be less than 15 characters."
                              },
                           validate: (value) => {
                               return (
                                   [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/,/[@#$%^&+=]/].every((pattern) =>
                                       pattern.test(value)
                                   ) || "Must include lower, upper, number, and special chars."
                               );

                           },
                           // 
                          
                       })}
                       maxLength={16}
                         className="form-control" 
                          placeholder="Enter your password"/>
                           {errors.password && <span className="error">{errors.password.message}</span>}
                                                            {/* {errors.password?.type == "required" &&
                                                             <span className="error">{errors.password.message}</span>} */}
                                                            {errors.password?.type == "validate1" && (
                                                                <span className="error">{errors.password.message}</span>
                                                            )}
                                                            
                      </div>
                      <div className="form-group">
                         <label >Confirm password</label>
                         <input type="password" 
                         {...register("confirmpasswords", {
                           //   required: true,

                           required: "Confirm password is required",
                           validate: (value) => {
                               const { password } = getValues();
                               return password == value || "Password does not match";
                           }

                       })}
                         className="form-control" 
                          placeholder="Confirm password"/>
                         {errors.confirmpasswords && <span className="error">
                         {errors.confirmpasswords.message}</span>}
                                                            
                      </div>
                      <div className="form-group">
                         <label >Phone number</label>
                         
                      <input
                     
                      placeholder="eg: 9447 752 786"
                     value={mobile}
                     onChange={(e)=>setmobile(e.target.value)}
                     onKeyPress={preventOtherCharactersFromNumberInputcount}
                         className="form-control" 
                         type="text"
                         maxLength={10}  
                         minLength={10}                   
                         />                                    
                      </div>                     
                   <div className="form-group" >
                         <button type="submit" className="btn login-btn">Create an account</button>
                      </div>
                      <div className="form-group form-check d-flex align-items-lg-end">
                       <input type="checkbox" className="form-check-input"
                       checked={isChecked}
                       onChange={handleCheckboxChange} id="exampleCheck1" />
                       <label className="form-check-label agree" for="exampleCheck1"style={{marginBottom:"-1px",wordBreak:"break-all",fontSize:"11px"}}>I agree to receive email communication from Getlead</label>
                     </div>
                     <div className="form-group signup-grp">
                                <a>Already a Getlead user?</a> <br/>
                                <button type="button" className="btn signup-btn" onClick={e=>navigate('/Login')}>Login</button>    
                              </div>
                     
                      
                   </form>
                   <div className="signup-texts">
                      <p>By signing up, you agree to our <span>Terms of Service</span> and <span>Privacy Notice.</span> This page is protected by reCAPTCHA <br/>
                         and the <span className="text-dec">Google Privacy Policy</span> and <span className="text-dec">Terms of Service</span> apply.</p>
                   </div>
                </div>
             </div>
          </div>
          <ToastContainer/>
       </div>
    </div>
 </div>
  )
}

export default Signup