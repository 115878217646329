import React, { useState } from 'react'
import help from '../../assets/images/help.svg';
import logo from '../../assets/images/login-logo.svg';
import layout from '../../assets/images/layout-img.svg';
import '../../assets/css/style.css';
import '../../assets/css/resp.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import axios from 'axios';
import Loader from '../../Components/Loader/Loader';
const { REACT_APP_AUTH_SERVER}= process.env;
const {REACT_APP_SERVER_URL}=process.env;
const Resetpassword = () => {
    const location=useLocation()
    const navigate=useNavigate();
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        setValues,
        setFocus,
        values,
        reset,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const [email, setemail] = useState(location?.state?.email)
    const [loading, setloading] = useState(false)
    const handleSubmitForm = async (e) => {
        if (isValid) {
            setloading(true)
          await axios({
            url: REACT_APP_AUTH_SERVER + "/api/update-password",
            method: 'POST',
            data: {
              email: email,
              password: getValues().password,
              confirm_password	: getValues().confirmpasswords
            },
          }
          ).then((response) => {
            // console.log(response.data)
            if (response.data.success==true) {
          
           setloading(false)
           toast.success(response.data.message)
                navigate('/Login')
                Swal.fire({
                    icon: 'success',
                    text: 'Password updated successfully!',
                    showConfirmButton: false,
                    timer: 2000
                  })
            }
            else {
                toast.error(response.data.message)
                setloading(false)
            
            }
            // window.location.reload();
          })
          .catch((err) => {
            if(err.request){ console.log(err.request) } if(err.response){ 
                console.log(err.response)
                setloading(false)
          }})
        }
        setloading(false)
      }
  return (
    <div>
        <Loader loading={loading}/>
        <div className="login-page">
        <div className="container-fluid">
            <div className="row ">
                <div className="col-lg-5 col-md-5 col-sm-12 p-0">
                    <div className="login-slider">
                        <div className="login-logo">
                            <img src={logo} alt="Getlead"/>
                        </div>
                        <div className="layout-img">
                            <img src={layout} alt="Getlead"/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12 p-0">
                    <div className="login-form d-flex align-items-center justify-content-center">
                        <div className="form-col">
                            <div className="help-text">
                                <a href="#"><span><img src={help} alt="Getlead"/> </span> Help center</a>
                            </div>
                            <form onSubmit={handleSubmit(handleSubmitForm)}>
                                <div className="form-header">
                                    <h2>Create new password</h2>
                                </div>
                                  <div className="form-group">
                                    <label >Enter new password</label>
                                    <input type="password" 
                                    maxLength={16}
                                    {...register("password", {
                                                            required: "Password is required",
                                                            minLength: {
                                                            value: 7,
                                                            message: "Password must have at least 7 characters."
                                                            },
                                                            maxLength: {
                                                            value: 15,
                                                            message: "Password must be less than 15 characters."
                                                            },
                                                            validate: (value) => {
                                                            return (
                                                            [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/,/[@#$%^&+=]/].every((pattern) =>
                                                            pattern.test(value)
                                                            ) || "Must include lower, upper, number, and special chars."
                                                            );

                                                            },
                                                        })}className="form-control"  placeholder="Enter your password"/>
                                                        {errors.password && <span className="error">{errors.password.message}</span>}
                                                        {errors.password?.type == "validate1" && (
                                                        <span className="error">{errors.password.message}</span>)}
                                    
                                  </div>
                                  <div className="form-group">
                                    <label >Re-enter password</label>
                                    <input type="password" className="form-control"
                                    {...register("confirmpasswords", {
                                        //   required: true,

                                        required: "Confirm password is required",
                                        validate: (value) => {
                                            const { password } = getValues();
                                            return password == value || "Password does not match";
                                        }

                                    })}
                                    maxLength={25}
                                    placeholder="Re-enter password"/>
                                     {errors.confirmpasswords && <span className="error">
                                    {errors.confirmpasswords.message}</span>}
                                  </div>
                                  <button type="submit" className="btn login-btn">Create new password</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </div>
    </div>
    </div>
  )
}

export default Resetpassword