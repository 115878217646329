import React, { useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Searchicon from '../../assets/images/search.svg'
import { useDispatch, useSelector } from 'react-redux';
import Pagination from "@mui/material/Pagination";
import { fetchUserdeparment } from '../../redux/UserDepartment';
import UpdateUser from './UpdateUser';
import { fetchUserDetails } from '../../redux/UserDetails';
import dot from '../../assets/images/3dot.svg'

const { REACT_APP_AUTH_SERVER } = process.env;
const { REACT_APP_SERVER_URL } = process.env;
const Usertab = () => {
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        setValues,
        setFocus,
        values,
        reset,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [mobile, setmobile] = useState()

    const [loading, setloading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setpage] = useState(1)
    const [dropdown, setdropdown] = useState(false)
    const token = localStorage.getItem("token")
    const [desg, setdesg] = useState()
    const [id, setid] = useState();
    const [searchusr, setsearchuse] = useState();
    const [openDropdown, setOpenDropdown] = useState(null);
    const { userdpt_list, userlist, departmentlist, designationlist, usercount } = useSelector((state) => state.userdepartment);
    // console.log(designationlist, "user");

    // const {  userlist } = useSelector((state) => state.userlist);
    // console.log(userlist,"kkkkkkkkkkkkkkkkk");
    // const ITEMS_PER_PAGE = 10;
    // const totalPages = Math.ceil(user.length / ITEMS_PER_PAGE);

    // // Slice the data array to get the data for the current page
    // const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    // const endIndex = startIndex + ITEMS_PER_PAGE;
    // const currentData = user.slice(startIndex, endIndex);
    // const handlePageChange = (pageNumber) => {
    //     setCurrentPage(pageNumber);
    // };
    const CreateUser = () => {

        setloading(true)
        axios({
            url: `${REACT_APP_SERVER_URL}/api/add-user`,
            method: 'POST',
            headers: { "Authorization": `Bearer ${token}` },
            data: {
                name: getValues().name,
                email: getValues().email,
                // phone:getValues().mobile,
                phone: mobile,
                password: getValues().password,
                confirm_password: getValues().password,
                user_type: 1,
                designation_id: getValues().designation,
            }
        }).then((res) => {

            if (res.data.success) {
                toast.success(res.data.message)
                dispatch(fetchUserdeparment({ token: token, page: page }))
                const addCustomerModal = document.getElementById('closeButton1');
                addCustomerModal.click();
                // addCustomerModal.style.display = 'none';

                setmobile('')
                reset({
                    'name': '',
                    'email': '',
                    'password': '',
                    'designation': ''

                });
                // setdesg('')
                setloading(false)

            }
            else {

                toast.error(res.data.data.email[0])
                setloading(false)
            }
        }).catch((err) => {

            if (err.request) {

                console.log(err.request)
            } if (err.response) {
                // console.log(err.response, 'jjjjjjjjjjjj')
                toast.error(err.response.data.data.email[0])
                setloading(false)
            }
        })

    }
    const toggleDropdown = (index) => {
        // alert("t");

        if (openDropdown === index) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(index);
        }
    };
    function preventOtherCharactersFromNumberInputcount(evt) {
        if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57 || evt.target.value.length == 10) {
            evt.preventDefault();
        }
    }
    const closeadduser = () => {

    }
    const changePage = (value) => {

        console.log();
        dispatch(fetchUserdeparment({ token: token, page: value }))
    }
    const searchuser = () => {

        dispatch(fetchUserdeparment({ token: token, page: page, search: searchusr }))
    }
    const Closedeletemodal = () => {
        const addCustomerModal = document.getElementById('closeButton2');
        addCustomerModal.click();
        // addCustomerModal.style.display = 'none';
    }
    const deleteuser = () => {
        // console.log(id,"aaaaaaaaaaaaa");
        axios({
            url: `${REACT_APP_SERVER_URL}/api/delete-user`,
            method: 'POST',
            headers: { "Authorization": `Bearer ${token}` },
            data: {
                user_id: id
            }

        }).then((res) => {
            // console.log(res.data, "success");
            if (res.data.success) {
                toast.success(res.data.message)
                Closedeletemodal()
                dispatch(fetchUserdeparment(token, page))
                setloading(false)

            }
            else {

                toast.error(res.data.data.email[0])
                setloading(false)
            }
        }).catch((err) => {

            if (err.request) {

                console.log(err.request)
            } if (err.response) {
                // console.log(err.response,'jjjjjjjjjjjj')
                // toast.error(err.response.data.data.email[0])
                setloading(false)
            }
        })

    }
    const getUser = (id) => {
        dispatch(fetchUserDetails({ token: token, id: id }))
        setid(id)
    }
    // console.log(id,"bbbbbbbbbbbbbbbb");
    return (

        <div className="users-sec">
            <div className="users-count">
                <div className="users-single">
                    <p className="u-count">{userdpt_list.user_permission}</p>
                    <p>User permissions</p>
                </div>
                <div className="users-single" style={{ background: "#383699" }}>
                    <p className="u-count">{userdpt_list.active_user}</p>
                    <p>Active users</p>
                </div>
                <div className="users-single" style={{ background: "#37537e" }}>
                    <p className="u-count">{userdpt_list.available}</p>
                    <p>Available</p>
                </div>
            </div>
            <div className="filter-report align-items-center pt-4">
                <div className="report-head">
                    <h4>Active users</h4>
                </div>
                <div className="export-col add-col d-flex align-items-center">
                    <div className="input-group">
                        <input type="text" className="form-control" value={searchusr} onChange={(e) => setsearchuse(e.target.value)} placeholder="Search user" />
                        <div className="input-group-append">
                            <button className="btn" type="button" onClick={searchuser}>
                                <img src={Searchicon}></img>
                            </button>
                        </div>
                    </div>
                    <div className="export-col add-col ml-5 addlog">
                        <button data-toggle="modal" data-target="#add-user">
                            Add users&nbsp;&nbsp;
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                <path
                                    fill="currentColor"
                                    d="M11 17h2v-4h4v-2h-4V7h-2v4H7v2h4Zm1 5q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4Q8.65 4 6.325 6.325Q4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"
                                ></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className="gl-table table-active">
                <table>
                    <thead>
                        <tr>
                            <th>
                                <label className="container-checkbox">
                                    <input type="checkbox" />
                                    <span className="checkmark"></span>
                                </label>
                            </th>
                            <th>Agent name</th>
                            <th>Mobile number</th>
                            <th>Email ID</th>
                            <th>Designation</th>
                            <th>Role</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userlist?.map((usr, index) => {
                            return (

                                <tr>
                                    <td>
                                        <label className="container-checkbox">
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </td>
                                    <td>{usr?.name}</td>
                                    <td>{usr?.phone}</td>
                                    <td></td>
                                    <td>{usr?.designation?.designation}</td>
                                    <td>
                                        {usr?.role_id == 1 ? "Admin" : "Staff"}
                                    </td>
                                    <td style={{ color: usr?.status == 1 ? "green" : "#ff5e5e" }}>{usr?.status == 1 ? "Active" : "Deactive"}</td>
                                    <td>
                                        <div className="dropdown show dropdown-dots dropdown-show" style={{ background: "" }} onClick={(e) => {
                                            e.preventDefault();
                                            toggleDropdown(index);
                                        }} >
                                            <a className="btn dropdown-toggle" href="#" role="button" id={`dropdownMenuLink-${index}`}

                                            // data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            >
                                                {/* <i className="fa fa-circle" aria-hidden="true"></i>
<i className="fa fa-circle" aria-hidden="true"></i>
<i className="fa fa-circle" aria-hidden="true"></i> */}
                                                {/* <i className="fa fa-ellipsis-v dots" aria-hidden="true"></i> */}
                                                <img src={dot} alt=''></img>
                                            </a>

                                            {openDropdown === index && (
                                                <div className="dropdown-menu" aria-labelledby={`dropdownMenuLink-${index}`}>
                                                    <a className="dropdown-item" href="#" id="14" data-target="#update-user" data-toggle="modal"
                                                        onClick={() => getUser(usr.user_id)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
                                                            <path
                                                                fill="currentColor"
                                                                d="M2 26h28v2H2zM25.4 9c.8-.8.8-2 0-2.8l-3.6-3.6c-.8-.8-2-.8-2.8 0l-15 15V24h6.4l15-15zm-5-5L24 7.6l-3 3L17.4 7l3-3zM6 22v-3.6l10-10l3.6 3.6l-10 10H6z"
                                                            ></path>
                                                        </svg>
                                                        &nbsp;Edit
                                                    </a>
                                                    <a href="#" data-toggle="modal" data-target="#deleteuser" className="dropdown-item lead-delete" onClick={() => setid(usr.id)}> <i className="fa fa-trash-o" aria-hidden="true"></i>&nbsp;Delete </a>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}


                    </tbody>
                </table>

                <div>

                </div>
            </div>
            {usercount?.total > 0 ?

                <Pagination
                    count={usercount?.total_pages}
                    color="primary"
                    variant="outlined"
                    onChange={(e, value) => changePage(value)}
                    page={usercount?.current_page}
                    style={{ marginTop: "15px", float: "right" }}

                />
                : " "
            }
            {/* <!-- Add users modal --> */}

            <div className="modal invite-modal fade" id="add-user" tabindex="-1" data-toggle="modal" role="dialog" aria-labelledby="myModalLabel-task" aria-hidden="true">
                <div className="modal-dialog modal-dialog-md t-invite-modal" role="document">
                    <div className="modal-content">

                        <div className="modal-body">
                            <div className="invitation-col">
                                <h5>Add user</h5>
                                <p>
                                    To get your team members in<br />
                                    Getlead CRM add them
                                </p>
                                <form className="invite-form" onSubmit={handleSubmit(CreateUser)}>

                                    <input type="text"

                                        autoComplete="off"
                                        {...register("name", {
                                            required: true,
                                            maxLength: {
                                                value: 50,
                                                message: "Name must be less than 50 characters."
                                            },
                                        })}
                                        className="form-control"
                                        aria-describedby="emailHelp"
                                        placeholder="Enter Name" />
                                    {errors.name?.type === "required" && (
                                        <span className="error">Name is required</span>
                                    )}
                                    {errors.name && <span className="error">{errors.name.message}</span>}

                                    <input
                                        {...register("email", {
                                            required: true,
                                            pattern:
                                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

                                        })}

                                        className="form-control"
                                        aria-describedby="emailHelp"
                                        placeholder="Enter Email" />
                                    {errors.email?.type === "required" && (
                                        <span className="error">Email is required</span>
                                    )}
                                    {errors.email?.type === "pattern" && (
                                        <span className="error">Email is not valid</span>
                                    )}
                                    {errors.email && <span className="error">{errors.email.message}</span>}
                                    <input type="password"
                                        {...register("password", {
                                            //   required: true,

                                            required: "Password is required",
                                            minLength: {
                                                value: 7,
                                                message: "Password must have at least 7 characters."
                                            },
                                            maxLength: {
                                                value: 15,
                                                message: "Password must be less than 15 characters."
                                            },
                                            validate: (value) => {
                                                return (
                                                    [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/, /[@#$%^&+=]/].every((pattern) =>
                                                        pattern.test(value)
                                                    ) || "Must include lower, upper, number, and special chars."
                                                );

                                            },
                                            // 

                                        })}
                                        maxLength={16}
                                        className="form-control"
                                        placeholder="Enter your password" />
                                    {errors.password && <span className="error">{errors.password.message}</span>}

                                    <select

                                        className="form-control"
                                        aria-describedby="emailHelp"
                                        {...register("designation", {
                                            required: true,
                                        })}
                                        required
                                    // value={desg}
                                    // onChange={(e)=>setdesg(e.target.value)}
                                    >
                                        <option value="">--Select Designation--</option>
                                        {designationlist?.map((des) => {
                                            return (

                                                <option value={des?.id}>{des?.designation}</option>
                                            )
                                        })}
                                    </select>
                                    {errors.designation?.type === "required" && (
                                        <span className="error">Designation is required</span>
                                    )}
                                    <input

                                        placeholder="eg: 9447 752 786"
                                        value={mobile}
                                        onChange={(e) => setmobile(e.target.value)}
                                        onKeyPress={preventOtherCharactersFromNumberInputcount}
                                        type="text"
                                        maxLength={10}
                                        className="form-control"
                                    />
                                    <button className="setup-btn" type='submit'>Save</button>
                                    <a className="close-bt-new" id="closeButton1" data-toggle="modal" data-target="#add-user">Save</a>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal invite-modal fade" id="update-user" tabindex="-1" data-toggle="modal" role="dialog" aria-labelledby="myModalLabel-task" aria-hidden="true">
                <UpdateUser userid={id} page={page} />
                <button className="close-bt-new" id="closeButton3" data-toggle="modal" data-target="#update-user">Save</button>
            </div>
            <div className="modal fade" id="deleteuser" role="dialog" aria-hidden="true" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>

                <div className="modal-dialog ticket-modal customer-modal modal-dialog-centered" role="document" style={{ width: "450px" }}>
                    <div className="modal-content">
                        <div className="modal-body" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>

                            <h3 className="text-center"> Are you sure to delete this User ?</h3>
                            <div className="row">


                                <div className="col-sm-12">
                                    <div className="form-group text-center ">
                                        <button className='deteletno mr-4' data-target="#deleteuser" data-toggle="modal" id="closeButton2">no</button>
                                        <button className='btn deteletyes w-20' onClick={deleteuser}>yes</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default Usertab 