import React from 'react'
import notification2 from '../../assets/images/notification-2.svg';

const Notification = () => {
  return (
    <div className="modal-dialog modal-dialog-lg ticket-modal modal-left" role="document">
    <div className="modal-content">
        <div className="modal-header">
            <h3 className="d-flex align-items-center modal-title pb-0 pt-3"><img src={notification2} alt="GetLead" />&nbsp; Notifications</h3>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div className="modal-body pt-1">
            <div className="notifications">
                <div className="single-notication">
                    <p>Getlead demo added new task to Sharundas</p>
                    <p className="date">Jan 14 at 2:40 PM</p>
                </div>
                <div className="single-notication">
                    <p>Getlead demo added new task to Sharundas</p>
                    <p className="date">Jan 14 at 2:40 PM</p>
                </div>
                <div className="single-notication">
                    <p>Getlead demo added new task to Sharundas</p>
                    <p className="date">Jan 14 at 2:40 PM</p>
                </div>
                <div className="single-notication">
                    <p>Getlead demo added new task to Sharundas</p>
                    <p className="date">Jan 14 at 2:40 PM</p>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Notification