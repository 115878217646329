import React from 'react'
import topmenu from '../../assets/images/top-menu.svg';
import notification from '../../assets/images/notification.svg';
import profile from '../../assets/images/profile-2.svg';
import { confirmAlert } from 'react-confirm-alert'
import { useNavigate } from 'react-router-dom';
import Notification from './Notification';
import { useEffect } from 'react';
import { useState } from 'react';

const ProfileNavbar = () => {
   
    const uname = localStorage.getItem("uname") 
    const navigate = useNavigate()
    const handleLogout=()=>{
       
        localStorage.removeItem("uname")
        localStorage.removeItem("token")
         navigate('/Login')
         
      
}
const Closemodal=()=>{
const addCustomerModal = document.getElementById('logoutmodal');
addCustomerModal.style.display = 'none'; 
}
const handlename = (e) => {      
        
    const parentElement = e.target.closest('.dropdown');
            parentElement.classList.toggle('dropdown-show');
}
  return (
    <div className="team-header">
                <div className="welcome-text">
                    {/* <!-- <h3>Welcome Sharun</h3>
                <p>Let’s build your workflow in few steps</p> --> */}
                </div>
                <div className="plan-col">
                    <div className="export-col add-col mr-5">
                        <button data-toggle="modal" data-target="#ticketModal">
                        <div className='addticket'>Add Ticket&nbsp;&nbsp;</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                <path
                                    fill="currentColor"
                                    d="M11 17h2v-4h4v-2h-4V7h-2v4H7v2h4Zm1 5q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4Q8.65 4 6.325 6.325Q4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    <ul className="header-icons">
                        <li>
                            <a href="#">
                                <img src={topmenu} alt="getlead" />
                            </a>
                        </li>
                        <li>
                        <a href="#" data-target="#notificationModal" data-toggle="modal">
                                <img src={notification} alt="getlead" />
                            </a>
                        </li>
                        <li>
                            <div className="dropdown show">
                                 <a className="dropdown-toggle d-flex align-items-center"
                             onClick={(e) => {
                                e.preventDefault();
                                handlename(e)
                            }}
                             href="#" role="button" id="dropdownMenuLink" >
                                    <img style={{width: "40px", height: "40px"}} src={profile} alt="getlead" />
                                    <span style={{ margin: "0px 0px 0 10px",textTransform:"capitalize" }}>{uname}<i className="fa fa-regular fa-chevron-down" style={{ margin: "0 10px" }}></i></span>
                                </a>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{marginTop:"10px"}}>
                                <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutmodal">Logout</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                {/* <!-- Notification modal --> */}
                                    <div className="modal fade notification-modal" id="notificationModal" role="dialog" aria-hidden="true">
                                      <Notification/>
                                    </div>
                                    <div className="modal fade" id="logoutmodal" role="dialog" aria-hidden="true" style={{boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>

                <div className="modal-dialog ticket-modal customer-modal modal-dialog-centered"  role="document" style={{width:"450px"}}>
                    <div className="modal-content">
                        <div className="modal-body" style={{boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
                        
                                <h3 className="text-center"> Are you sure to Logout ?</h3>
                                <div className="row">
                                    
                                
                                    <div className="col-sm-12">
                                        <div className="form-group text-center ">
                                                <button className='deteletno mr-4' onClick={Closemodal}>no</button>
                                        <button className='btn deteletyes w-20' onClick={handleLogout}>yes</button>
                                        </div>
                                    </div>
                                </div>
                            
                        </div>
                        </div>
                        </div>   
            </div>
            </div>
  )
}

export default ProfileNavbar