import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Login from "./Pages/Auth/Login";
import Signup from "./Pages/Auth/Signup";
import Forgotpassword from "./Pages/Auth/Forgotpassword";
import Customers from "./Pages/Customers/Customers";
import VerifyOTP from "./Pages/Auth/VerifyOTP";
import Resetpassword from "./Pages/Auth/Resetpassword";
import TicketListing from "./Pages/Tickets/TicketListing";
import SetupTeams from "./Pages/SetupTeams/SetupTeams";
import Customerticket from "./Pages/Tickets/Customerticket";
import TicketReport from "./Pages/Report/TicketReport";
import Profile from "./Pages/Profile/Profile";
import UsersandDprt from "./Pages/Profile/UsersandDprt";
import AssignedTicket from "./Pages/Tickets/AssignedTicket";
function App() {
  return (
    <div className="App">
      <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/forgotpassword" element={<Forgotpassword />} />
          <Route path="/verifyOtp" element={<VerifyOTP/>}/>
          <Route path="/updatepassword" element={<Resetpassword/>}/>
          <Route path="/ticket-listing" element={<TicketListing/>}/>
          <Route path="/ticket-listing/:id" element={<TicketListing/>}/>
          <Route path="/customer" element={<Customers />} />
          <Route path="/customer/:id" element={<Customers />} />
          <Route path="/customerticket/:customer_id" element={<Customerticket />} />
          <Route path="/ticketreport" element={<TicketReport />} />
          <Route path="/settings" element={<Profile />} />
          <Route path="/users-and-departments" element={<UsersandDprt />} />
          <Route path="/setupteams" element={<SetupTeams/>}/>
          <Route path="/assigned-ticket-listing" element={<AssignedTicket/>}/>
        </Routes>
    </div>
  );
}

export default App;
