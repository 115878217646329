import React, { createContext, useEffect, useRef, useState } from 'react'
import '../../assets/css/style.css';
import '../../assets/css/resp.css';
import home from '../../assets/images/home-icon.svg';
import topmenu from '../../assets/images/top-menu.svg';
import notification from '../../assets/images/notification.svg';
import profile from '../../assets/images/profile-2.svg';
import editvector from '../../assets/images/edit-vector.svg';
import deleteicon from '../../assets/images/delete.svg';
import edit from '../../assets/images/edit.svg'
import calender from '../../assets/images/calender.svg'
import phone from '../../assets/images/phone2.svg'
import mail from '../../assets/images/mail.svg'

import tick from '../../assets/images/tick-icon.svg'
import greytick from '../../assets/images/tick-circle.svg'
import cale_vector from '../../assets/images/cale-vector.svg'
import reschedule from '../../assets/images/reshedule.svg'
import notication2 from '../../assets/images/notification-2.svg'
import Select from "react-select";
import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import $ from 'jquery';
import 'select2';
import { useForm } from 'react-hook-form';
import UpdateCustomer from '../../Components/Customer/UpdateCustomer';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Datetime from 'react-datetime'
import "react-datetime/css/react-datetime.css";
import { ToastContainer, toast } from 'react-toastify';
import { fetchCustomerDatas } from "../../redux/Customerlist";
import AddTask from '../../Components/Task/AddTask';
import { confirmAlert } from 'react-confirm-alert'
import ActivityTab from '../../Components/Tab/ActivityTab';
import LogTab from '../../Components/Tab/LogTab';
import TaskTab from '../../Components/Tab/TaskTab';
import Rescheduletask from '../../Components/Task/Rescheduletask';
import { fetchTicketList } from '../../redux/TicketListSlice';
import { fetchTicketAssignList } from '../../redux/TikectlistAssign';
import { fetchTicketbyCustomer } from '../../redux/TicketbyCustList';
import moment from 'moment';

const { REACT_APP_AUTH_SERVER } = process.env;
const { REACT_APP_SERVER_URL } = process.env;
const EditTicket = ({ ticket,whichpage ,cusId}) => {
    const { customer_list } = useSelector((state) => state.customers);
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        control,
        setValue,
        setFocus,
        reset,
        watch,
        setError,
        clearErrors,
        values,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const token = localStorage.getItem("token")
    const dispatch = useDispatch();
    const { meta_list, prioArray, staArray, typeArray, asignArray, labArray, productArray, productId, checkproduct } = useSelector((state) => state.metalist);
    const { customer } = useSelector((state) => state.allcustomers);
    const { detail_list } = useSelector((state) => state.detaillist);
    const [product, setproduct] = useState([])
    const [agent, setagent] = useState([])
    const [startDate, setStartDate] = useState(new Date());
    const [assignedto, setassignedto] = useState([]);

    const [notifyto, setnotifyto] = useState([]);
    const [priority, setpriority] = useState([]);
    const [label, setlabel] = useState([]);
    const [Img, setImg] = useState([]);
    const [cus, setcus] = useState();
    const [tabNum, setTabNum] = useState(0)
    
    useEffect(() => {
        // setagent(ticket?.agent)
        setassignedto(detail_list?.agent?.map(user => {
            return {
                value: parseInt(user.user_id),
                label: user.name
            };
        }))
        setnotifyto(detail_list?.notify_to?.map(user => {
            return {
                value: parseInt(user.user_id),
                label: user.name
            };
        }))
        // setpriority(detail_list?.ticket_priority?.map(user => {
        //     return {
        //         value: parseInt(user.id),
        //         label: user.title
        //     };
        // }))
        setpriority({value:parseInt(detail_list?.ticket_priority?.id), label:detail_list?.ticket_priority?.title})
        setlabel({value:parseInt(detail_list?.ticket_label?.id), label:detail_list?.ticket_label?.label})
        setproduct(detail_list?.additional_data ? detail_list.additional_data[0]?.values?.map((res) => {
            return {

                value: parseInt(res?.id),
                label: res?.label
            }
        }
        ) : [])
        setStartDate(detail_list?.due_date)
        setValue("tick", {
            issue: detail_list?.issue,
            description: detail_list?.description,
            customer: detail_list?.customer_id,
            priority: parseInt(detail_list?.ticket_priority?.id),
            status: detail_list?.status,
            type: detail_list?.ticket_type,
            label: parseInt(detail_list?.ticket_label?.id),
            due_date: detail_list?.due_date,
            image: detail_list?.image ? detail_list?.image[0] : ''
        })
        setcus(detail_list?.customer_id)
     
    }, [detail_list]);


    const handleAssignedto = (value) => {
        // setValue("tick.assignedto", value)
        setassignedto(value)

    }
    const handlepriority = (value) => {
        setValue("tick.priority", value.value)
        setpriority(value)

    }
    const handlelabel = (value) => {
        setValue("tick.label", value.value)
        setlabel(value)

    }
    const handleNotifyto = (value) => {
        setnotifyto(value)
        // setValue("tick.notifyto", value)
    }
    const handleproduct = (value) => {
        // setValue("tick.productname", value)
        setproduct(value)
    }
    const handleFileChange = (e) => {
        setImg(e.target.files[0])
    }
    const handleUpdateticket = () => {
        // console.log(Img, 'pro');
        const additional = [{
            [productId]: product ? Object.values(product).map(item => item.value) : []
        }];
        let assigned = assignedto.map((val) => {
            return (
                parseInt(val.value)
            )
        })
        let notified = notifyto.map((val) => {
            return (
                parseInt(val.value)
            )
        })
        // const formData = new FormData();
        // formData.append('file', imageFile);
        axios({
            url: `${REACT_APP_SERVER_URL}/api/tickets/${ticket?.id}`,
            method: "PUT",
            headers: { "Authorization": `Bearer ${token}` },
            data: {
                issue: getValues().tick.issue,
                description: getValues().tick.description,
                customer_id: Number(cus),
                priority: Number(getValues().tick.priority),
                status: Number(getValues().tick.status),
                due_date: startDate ? startDate : '',
                assigned_to: assigned,
                notify_to: notified,
                ticket_type: Number(getValues().tick.type),
                ticket_label: Number(getValues().tick.label),
                additional_field_values: additional,
                image: Img
            }
        }).then((res) => {
            toast.success(res.data.message)
            // window.location.reload()
            document.body.classList.remove('modal-open');
            dispatch(fetchTicketbyCustomer({ token: token, id: cusId }))
            {
                whichpage ?
                    dispatch(fetchTicketList({
                        search: '',
                        filter_by: "",
                        status: [],
                        end_date: "",
                        priority: "",
                        agent: [],
                        start_date: "",
                        token: token,
                        current_page: 1,
                    })) :
                    dispatch(fetchTicketAssignList({
                        search: "",
                        filter_by: "",
                        status: [],
                        end_date: "",
                        priority: "",
                        agent: [],
                        start_date: "",
                        token: token,
                        current_page: 1,
                    }))
            }
        }).catch((err) => {
            if (err.request) { console.log(err.request) } if (err.response) {
                console.log(err.response)

            }
        })
    }


    const handleDate = (date) => {
        const dateObj = new Date(date?._d);
        const formattedDate = `${dateObj.getUTCFullYear()}-${String(dateObj.getUTCMonth() + 1).padStart(2, '0')}-${String(dateObj.getUTCDate()).padStart(2, '0')} ${String(dateObj.getUTCHours()).padStart(2, '0')}:${String(dateObj.getUTCMinutes()).padStart(2, '0')}:${String(dateObj.getUTCSeconds()).padStart(2, '0')}`;
        setStartDate(formattedDate)
    }
    const updatecustomerClose = () => {
        const addCustomerModal = document.getElementById('basic-info');
        addCustomerModal.style.display = 'none';
    }
    const minDate = moment(); // Define the minimum date

    const isValidDate = current => {
        return current.isAfter(minDate, 'day'); // Check if the selected date is after the minimum date
    };
    const selectRef1 = useRef(null);

  useEffect(() => {
    // Initialize Select2 when the component mounts
    $(selectRef1.current).select2({
      theme: 'bootstrap4',
      width: 'style',
      placeholder: $(selectRef1.current).attr('placeholder'),
      allowClear: Boolean($(selectRef1.current).data('allow-clear')),
    });
    $(selectRef1.current).on('change', (event) => {
        //alert("test");
      const selectedValue = event.target.value;
      setcus(event.target.value)
     
    });
    if(cus){
        $(selectRef1.current).val(cus).trigger('change');
    }else {
        $(selectRef1.current).val(ticket.customer_id).trigger('change');
    }
    
    
    // alert(selectRef1.current.value)
    // $(selectRef1.current).trigger('change');

    return () => {
      $(selectRef1.current).select2('destroy');
    };
  }, []);
  const selectRef2 = useRef(null);

  useEffect(() => {
    // Initialize Select2 when the component mounts
    $(selectRef2.current).select2({
      theme: 'bootstrap4',
      width: 'style',
      placeholder: $(selectRef2.current).attr('placeholder'),
      allowClear: Boolean($(selectRef2.current).data('allow-clear')),
    });
    $(selectRef2.current).on('change', (event) => {
      const selectedValue = event.target.value;
      
    
    });

    // Clean up Select2 instance when the component unmounts
    return () => {
      $(selectRef2.current).select2('destroy');
    };
  }, []);
  const selectRef3 = useRef(null);

  useEffect(() => {
    // Initialize Select2 when the component mounts
    $(selectRef3.current).select2({
      theme: 'bootstrap4',
      width: 'style',
      placeholder: $(selectRef3.current).attr('placeholder'),
      allowClear: Boolean($(selectRef3.current).data('allow-clear')),
    });
    $(selectRef3.current).on('change', (event) => {
      const selectedValue = event.target.value;
     
      
    });

    // Clean up Select2 instance when the component unmounts
    return () => {
      $(selectRef3.current).select2('destroy');
    };
  }, []);
  const selectRef4 = useRef(null);

  useEffect(() => {
    // Initialize Select2 when the component mounts
    $(selectRef4.current).select2({
      theme: 'bootstrap4',
      width: 'style',
      placeholder: $(selectRef4.current).attr('placeholder'),
      allowClear: Boolean($(selectRef4.current).data('allow-clear')),
    });
    $(selectRef4.current).on('change', (event) => {
      const selectedValue = event.target.value;
     
      
    });

    // Clean up Select2 instance when the component unmounts
    return () => {
      $(selectRef4.current).select2('destroy');
    };
  }, []);
  const selectRef5 = useRef(null);

  useEffect(() => {
    // Initialize Select2 when the component mounts
    $(selectRef5.current).select2({
      theme: 'bootstrap4',
      width: 'style',
      placeholder: $(selectRef5.current).attr('placeholder'),
      allowClear: Boolean($(selectRef5.current).data('allow-clear')),
    });
    $(selectRef5.current).on('change', (event) => {
      const selectedValue = event.target.value;
     
      
    });

    // Clean up Select2 instance when the component unmounts
    return () => {
      $(selectRef5.current).select2('destroy');
    };
  }, []);
    return (
        <>
            <div className="modal-dialog modal-dialog-lg ticket-modal ticket-detail modal-left" role="document">
                <div className="modal-content datefield">
                    <div className="modal-body">
                        <div className="ticket-detail-col">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="about-ticket">
                                        <h4 className="pb-2">#{detail_list?.id}</h4>
                                        <div className="d-flex align-items-center pb-3">
                                            <h3 className="p-0">{detail_list?.issue}</h3>
                                            <div >
                                                <a href="#" data-toggle="modal" data-target="#basic-info" >
                                                    <img className='penci' src={edit} alt="Getlead" />
                                                </a>
                                            </div>
                                        </div>
                                        <p>{detail_list?.description}</p>
                                        <div className="contact-details">
                                            <h5>Contact details</h5>
                                            <div className="d-flex align align-items-center pb-1 pt-2">
                                                <img src={phone} alt="Getlead" />
                                                <a href="tel:+919774452786">{customer_list ? customer_list['customer']?.country_code : ''}{customer_list ? customer_list['customer']?.contact : ''}</a>
                                            </div>
                                            <div className="d-flex align align-items-center">
                                                <img src={mail} alt="Getlead" />
                                                <a href="mailto:sayyidshabar@getlead.co.uk">{customer_list ? customer_list['customer']?.email : ''}</a>
                                            </div>
                                        </div>
                                        <div className="update-form-col">
                                            <form action="" onSubmit={handleSubmit(handleUpdateticket)}>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-12 col-lg-12 p-1">
                                                        <div className="form-group">
                                                            <label htmlFor="">Product name</label>
                                                            {checkproduct?.selection === "single" ? <>
                                                                <Select
                                                                    // {...register("ticket.productname", {
                                                                    //     required: "Product to is required",
                                                                    // })}
                                                                    required={checkproduct?.mandatory}
                                                                    name='productname'
                                                                    options={productArray}
                                                                    value={product}
                                                                    onChange={(value) => handleproduct(value)}
                                                                />
                                                            </> : <>
                                                                <Select isMulti
                                                                    // {...register("ticket.productname", {
                                                                    //     required: "Product to is required",
                                                                    // })}
                                                                    required={checkproduct?.mandatory}
                                                                    name='productname'
                                                                    options={productArray}
                                                                    value={product}
                                                                    onChange={(value) => handleproduct(value)}
                                                                />
                                                            </>}

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12 col-lg-6 p-1">
                                                        <div className="form-group">
                                                            <label htmlFor="">Customer</label>

                                                            <select className="form-control"
                                                                {...register("tick.customer", {
                                                                    required: true,
                                                                })}
                                                                // ref={selectRef1}
                                                                name='customer'
                                                                value={cus}
                                                                onChange={(value) => setcus(value.target.value)}
                                                            >
                                                                <option value=''>--select--</option>
                                                                {customer?.map((cust) => {
                                                                    return (

                                                                        <option value={cust?.id}>{cust.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            {errors.customer?.type === "required" && (
                                                                <span className="error">Customer is required</span>)}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12 col-lg-6 p-1">
                                                        <div className="form-group">
                                                            <label htmlFor="">Priority</label>
                                                            <Select
                                                    
                                                    name='priority'
                                                    // {...register("tick.priority", {
                                                    //     required: true,
                                                    // })}
                                                    options={prioArray}
                                                    value={priority}
                                                    placeholder="--Select--"
                                                    onChange={(value) => handlepriority(value)}
                                                />
                                                {/* {tick?.priority?.length === 0 && <ErrorMessage
                                                    errors={errors}
                                                    name="priority"
                                                    render={({ message }) => <span className="error">Assinged to is required</span>}
                                                />} */}
                                                            {/* <select className="form-control"
                                                                name='priority'
                                                                ref={selectRef2}
                                                                {...register("tick.priority", {
                                                                    required: true,
                                                                })}>
                                                                <option value=''>--select--</option>
                                                                {prioArray?.map((pri) => {
                                                                    return (

                                                                        <option value={pri.value}>{pri.label}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            {errors.priority?.type === "required" && (
                                                                <span className="error">Priority is required</span>)} */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12 col-lg-6 p-1">
                                                        <div className="form-group">
                                                            <label htmlFor="">Assigned to</label>
                                                            <Select isMulti
                                                    name='assignedto'
                                                    {...register("tick.status", {
                                                                    required: true,
                                                                })}
                                                    options={asignArray}
                                                    value={assignedto}
                                                    placeholder="--Select--"
                                                    onChange={(value) => handleAssignedto(value)}
                                                />
                                                {assignedto?.length === 0 && <ErrorMessage
                                                    errors={errors}
                                                    name="assignedto"
                                                    render={({ message }) => <span className="error">Assinged to is required</span>}
                                                />}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12 col-lg-6 p-1">
                                                        <div className="form-group">
                                                            <label htmlFor="">Notify to</label>
                                                            <Select isMulti
                                                    name='notifyto'
                                                    
                                                    options={asignArray}
                                                    value={notifyto}
                                                    placeholder="--Select--"
                                                    onChange={(value) => handleNotifyto(value)}
                                                />
                                                {notifyto?.length === 0 && <ErrorMessage
                                                    errors={errors}
                                                    name="notifyto"
                                                    render={({ message }) => <span className="error">{message}</span>}
                                                />}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12 col-lg-6 p-1">
                                                        <div className="form-group">
                                                            <label htmlFor="">Status</label>
                                                            <select name="" id=""
                                                            ref={selectRef3}
                                                                {...register("tick.status", {
                                                                    required: true,
                                                                })} className="form-control">
                                                                <option value=''>--select--</option>
                                                                {staArray?.map((obj) => {
                                                                    return (

                                                                        <option value={obj.value}>{obj.label}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12 col-lg-6 p-1">
                                                        <div className="form-group">
                                                            <label htmlFor="">Type</label>
                                                            <select name="" id=""
                                                            ref={selectRef4}
                                                                {...register("tick.type", {
                                                                    required: true,
                                                                })} className="form-control">
                                                                <option value=''>--select--</option>
                                                                {typeArray?.map((obj) => {
                                                                    return (

                                                                        <option value={obj.value}>{obj.label}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12 col-lg-6 p-1">
                                                        <div className="form-group">
                                                            <label htmlFor="">Label</label>
                                                            {/* <select name="" id=""
                                                            ref={selectRef5}
                                                                {...register("tick.label", {
                                                                    required: true,
                                                                })} className="form-control">
                                                                <option value=''>--select--</option>
                                                                {labArray?.map((obj) => {
                                                                    return (

                                                                        <option value={obj.value}>{obj.label}</option>
                                                                    )
                                                                })}
                                                            </select> */}
                                                            <Select
                                                    
                                                    name='label'
                                                    // {...register("tick.label", {
                                                    //     required: true,
                                                    // })}
                                                    options={labArray}
                                                    value={label}
                                                    placeholder="--Select--"
                                                    onChange={(value) => handlelabel(value)}
                                                />
                                                {/* {tick?.label?.length === 0 && <ErrorMessage
                                                    errors={errors}
                                                    name="label"
                                                    render={({ message }) => <span className="error">Label to is required</span>}
                                                />} */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12 col-lg-6 p-1">
                                                        <div className="form-group">
                                                            <label htmlFor="">Image</label>
                                                            <input type="file" accept="image/*"
                                                                onChange={handleFileChange}
                                                                name='image'
                                                                className="form-control" id="fecha1"

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12 col-lg-12 p-1">
                                                        <div className="form-group">
                                                            <label htmlFor="">Due on</label>
                                                            <div className="datepicker date input-group">
                                                                <Datetime
                                                                    name='date'
                                                                    isValidDate={isValidDate}
                                                                    value={startDate} onChange={(date) => handleDate(date)} />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">
                                                                        <img src={calender} alt="GetLead" />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12 col-lg-6 p-1">
                                                        <div className="form-btn">
                                                            <button className="add-ticket p-3 w-100" type='submit'>Update</button>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12 col-lg-6 p-1">
                                                        <div className="form-btn">
                                                            <button className="cancel-btn p-3 w-100" data-dismiss="modal" style={{ height: "48px" }}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-12">
                                    <div className="tab-col">
                                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" href="#activity" role="tab" onClick={(e) => setTabNum(0)} data-toggle="tab" aria-selected="true">
                                                    Activity
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="#log-note" onClick={(e) => setTabNum(1)} role="tab" data-toggle="tab">Log note</a>
                                            </li>
                                            <li className="nav-item" >
                                                <a className="nav-link" href="#task" onClick={(e) => setTabNum(2)} role="tab" data-toggle="tab">Task</a>
                                            </li>
                                        </ul>
                                        {/* <!-- Tab contents --> */}
                                        <div className="tab-content">
                                            <div role="tabpanel" className="tab-pane active activity-sec" id="activity">
                                                {tabNum === 0 ? <ActivityTab id={ticket?.id} /> : ''}
                                            </div>
                                            <div role="tabpanel" className="tab-pane fade" id="log-note">
                                                {tabNum === 1 ? <LogTab id={ticket?.id} /> : ''}
                                            </div>
                                            <div role="tabpanel" className="tab-pane fade" id="task">
                                                {tabNum === 2 ? <TaskTab id={ticket?.id} /> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="basic-info" role="dialog" aria-hidden="true">
                <UpdateCustomer customer_list={customer_list} updatecustomerClose={updatecustomerClose} />
            </div>
            {/* <!-- Reschedule modal --> */}

            {/* <ToastContainer /> */}
        </>
    )
}

export default EditTicket