import { createSlice } from "@reduxjs/toolkit";

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const {REACT_APP_SERVER_URL}=process.env;
export const fetchTicketAssignList = createAsyncThunk(
  "filters/fetchTicketAssignList",
  async (searchData, thunkAPI) => {
    try {
      const response = await axios.post(
        `${REACT_APP_SERVER_URL}/api/list-assigned-tickets`,
        {
            search:searchData.search?searchData.search:'',
            filter_by:searchData.filter_by?searchData.filter_by:'',
            status:(searchData.status=="all")?[]:searchData.status,
            end_date:searchData.end_date?searchData.end_date:"",
            priority:searchData.priority?searchData.priority:"",
            agent:(searchData.agent=="all")?[]:searchData.agent,
            start_date:searchData.start_date?searchData.start_date:"",
            page:searchData.current_page?searchData.current_page:1,
            per_page:6
        },
        {
          headers: {"Authorization" : `Bearer ${searchData?.token}`},
        }
      );
   
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const ticketListingAssign = createSlice({
  name: "ticketSearch",
  initialState: {
    search:"",
    filter_by:"",
    status:[],
    end_date:"",
    priority:"",
    agent:[],
    start_date:"",
    loading: true,
    error: "",
  },
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setFilter_by: (state, action) => {
      state.filter_by = action.payload;
    },
    setStatus: (state, action) => {
        state.status = action.payload;
    },
    setEnd_date: (state, action) => {
        state.end_date = action.payload;
    },
    setPriority: (state, action) => {
        state.priority = action.payload;
    },
    setAgent: (state, action) => {
        state.agent = action.payload;
    },
    setStart_date: (state, action) => {
        state.start_date = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTicketAssignList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTicketAssignList.fulfilled, (state, action) => {
        state.ticketList = action.payload.data.tickets.data;
        state.totalRecords = action.payload.data.tickets.pagination;
        state.loading = false;
      })
      .addCase(fetchTicketAssignList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { 
    setSearch,
    setFilter_by,
    setStatus,
    setEnd_date,
    setPriority,
    setAgent,
    setStart_date
 } = ticketListingAssign.actions;

export default ticketListingAssign.reducer;
