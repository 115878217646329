import axios from 'axios';
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import AddDepartment from './AddDepartment';
import $ from 'jquery'
import Pagination from "@mui/material/Pagination";
import { fetchUserdeparment } from '../../redux/UserDepartment';
import dot from '../../assets/images/3dot.svg'
const { REACT_APP_AUTH_SERVER } = process.env;
const { REACT_APP_SERVER_URL } = process.env;
const DepartmentTab = ({   }) => {
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        setValues,
        setFocus,
        values,
        reset,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    // console.log(department,"dpt");
    const token = localStorage.getItem("token")
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { asignArray } = useSelector((state) => state.metalist);///2///
    const { userdpt_list, userlist, departmentlist, departmentcount, designationlist, usercount } = useSelector((state) => state.userdepartment);

    // console.log(asignArray,"aaaaaaaaaaaaaaaaa");
    const [loading, setloading] = useState(false);
    const [assignedto, setassignedto] = useState([]);
    const [departmentname, setdepartmentname] = useState();
    const [dptid, setdptid] = useState();
    const [id, setid] = useState();
    const [openDropdown, setOpenDropdown] = useState(null);
    const myInputRef = useRef(null);

    const toggleDropdown = (index) => {
        // alert("t");
       
        if (openDropdown === index) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(index);
        }
    };
    const getDepartment = (id) => {
        let assign = assignedto.map((val) => {
            return (
                parseInt(val.value)
            )
        })
        axios({
            url: `${REACT_APP_SERVER_URL}/api/department/${id}`,
            method: 'GET',
            headers: { "Authorization": `Bearer ${token}` },
        }).then((res) => {
            console.log(res.data, "success");
            if (res.data.success) {
                setdepartmentname(res.data.data.department.department)
                // toast.success(res.data.message)
                setdptid(res.data.data.department.id)
                setassignedto(res?.data?.data?.department?.agent?.map(user => {
                    return {
                        value: parseInt(user.user_id),
                        label: user.name
                    };
                }))

            }
            else {

                //    toast.error(res.data.data.email[0])
                setloading(false)
            }
        }).catch((err) => {

            if (err.request) {

                console.log(err.request)
            } if (err.response) {
                // console.log(err.response,'jjjjjjjjjjjj')
                toast.error(err.response.data.data?.email[0])
                setloading(false)
            }
        })

    }
    const updateDepartment = () => {
        let assign = assignedto.map((val) => {
            return (
                parseInt(val.value)
            )
        })
        axios({
            url: `${REACT_APP_SERVER_URL}/api/department/${dptid}`,
            method: 'PUT',
            headers: { "Authorization": `Bearer ${token}` },
            data: {
                department: departmentname,
                agent: assign
            }
        }).then((res) => {
            // console.log(res.data, "success");
            if (res.data.success) {
                toast.success(res.data.message)
                dispatch(fetchUserdeparment(token, page))
                closedepartment1()
            }
            else {

                toast.error(res.data.data.email[0])
                setloading(false)
            }
        }).catch((err) => {

            if (err.request) {

                console.log(err.request)
            } if (err.response) {
                // console.log(err.response,'jjjjjjjjjjjj')
                toast.error(err.response.data.data.email[0])
                setloading(false)
            }
        })

    }

    const deletedepartment = () => {
        // console.log(id,"aaaaaaaaaaaaa");
        axios({
            url: `${REACT_APP_SERVER_URL}/api/department/${id}`,
            method: 'DELETE',
            headers: { "Authorization": `Bearer ${token}` },

        }).then((res) => {
            // console.log(res.data, "success");
            if (res.data.success) {
                toast.success(res.data.message)
                Closemodal()
                dispatch(fetchUserdeparment(token, page))
                setloading(false)

            }
            else {

                toast.error(res.data.data.email[0])
                setloading(false)
            }
        }).catch((err) => {

            if (err.request) {

                console.log(err.request)
            } if (err.response) {
                // console.log(err.response,'jjjjjjjjjjjj')
                // toast.error(err.response.data.data.email[0])
                setloading(false)
            }
        })

    }
    const handleAssignedto = (value) => {
        // setValue("assignedto",value)
        setassignedto(value)
    }
    const closedepartment1 = () => {
        // const addDptModal = document.getElementById('closeButton5');
        // addDptModal.style.display = 'none';
        // const updatdeptModal = document.getElementById('updateDept');
        // updatdeptModal.style.display = 'none';
        // document.addEventListener('DOMContentLoaded', function () {
        //     // Find the element with the data-dismiss attribute and add a click event listener to it
        //     document.querySelector('[data-dismiss="modal"]').addEventListener('click', function () {
        //         // Close the modal programmatically using Bootstrap's method
        //         $('#addDept').modal('hide');
        //         $('#updateDept').modal('hide');
        //     });
        // });
       // console.log('i am closed');
       const updatdeptModal = document.getElementById('closeButton5');
       updatdeptModal.click();
    }
    const closedepartment = () => {
       
    //    const updatdeptModal = document.getElementById('closeButton5');
    //    updatdeptModal.click();
    }
    const Closemodal = () => {
        var addCustomerModal = document.getElementById('closeFun');
        addCustomerModal.click()
        //myInputRef
        // addCustomerModal.classList.remove('show');
        // document.body.classList.remove('modal-open');
        // addCustomerModal.style.display = 'none';
    }
    const changePage = (value) => {

       
        dispatch(fetchUserdeparment({ token: token, page: value }))
    }
    return (
        <div>
            <div className="users-sec">
                <div className="filter-report align-items-center">
                    <div className="report-head">
                        <h4>Departments</h4>
                    </div>
                    <div className="export-col add-col d-flex align-items-center">
                        <div className="export-col add-col ml-5 addlog">
                            <button data-toggle="modal" data-target="#addDept" style={{ backgroundColor: "#117de0" }}>
                                Add Department&nbsp;&nbsp;
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                    <path
                                        fill="currentColor"
                                        d="M11 17h2v-4h4v-2h-4V7h-2v4H7v2h4Zm1 5q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4Q8.65 4 6.325 6.325Q4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="gl-table table-dept action">
                    <table>
                        <thead>
                            <tr>
                                <th>Department name</th>
                                <th>Agents</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {departmentlist?.map((dpt, index) => {
                                return (

                                    <tr key={index}>
                                        <td>{dpt.department}</td>
                                        <td> {dpt.agent.map((name, agentIndex) => (
                                            <span key={agentIndex}>{name.name} &nbsp;</span>
                                        ))}</td>

                                        <td>

                                            <div className="dropdown show dropdown-dots dropdown-show" style={{ background: "" }} onClick={(e) => {
                                                e.preventDefault();
                                                toggleDropdown(index);
                                            }} >
                                                <a className="btn dropdown-toggle" href="#" role="button" id={`dropdownMenuLink-${index}`}

                                                // data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                >
                                                 
                                                    {/* <i className="fa fa-ellipsis-v dots" aria-hidden="true"></i> */}
                                                    <img src={dot} alt=''></img>
                                                </a>
                                                {openDropdown === index && (
                                                    <div className="dropdown-menu" aria-labelledby={`dropdownMenuLink-${index}`}>
                                                        <a className="dropdown-item" href="#" onClick={() => getDepartment(dpt.id)} id="14" data-target="#updateDept" data-toggle="modal">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M2 26h28v2H2zM25.4 9c.8-.8.8-2 0-2.8l-3.6-3.6c-.8-.8-2-.8-2.8 0l-15 15V24h6.4l15-15zm-5-5L24 7.6l-3 3L17.4 7l3-3zM6 22v-3.6l10-10l3.6 3.6l-10 10H6z"
                                                                ></path>
                                                            </svg>
                                                            &nbsp;Edit
                                                        </a>
                                                        <a href="#" ref={myInputRef} data-toggle="modal" data-target="#deletedepartment" className="dropdown-item lead-delete close-modal" onClick={() => setid(dpt.id)}> <i className="fa fa-trash-o" aria-hidden="true"></i>&nbsp;Delete </a>
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>
            </div>
            {/* <Pagination
                count={departmentcount?.total_pages}
                color="primary"
                variant="outlined"
                onChange={(e, value) => changePage(value)}
                page={departmentcount?.current_page}
                style={{ marginTop: "15px", float: "right" }}

            /> */}
            {/* <!-- update dept modal --> */}
            <div className="modal fade" id="updateDept" role="dialog" aria-hidden="true">
                <div className="modal-dialog ticket-modal customer-modal modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <form className="add-ticket-form" onSubmit={handleSubmit(updateDepartment)}>
                                <h3 className="text-center">Update department</h3>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label for="">Department name</label>
                                            <input type="text" className="form-control"
                                                value={departmentname}
                                                maxLength={50}
                                                onChange={(e) => setdepartmentname(e.target.value)}
                                                required
                                                id="" aria-describedby="" placeholder="Enter department name" />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label for="">Add agents</label>
                                            <Select isMulti
                                                // {...register("assignedto", {
                                                //     required: "Assigned to is required",
                                                // })}
                                                name='assignedto'
                                                required
                                                options={asignArray}
                                                value={assignedto}
                                                placeholder="--Select--"
                                                onChange={(value) => handleAssignedto(value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group text-center mt-4">
                                            <button type="submit" className="add-ticket">Save</button>
                                            <a className="close-bt-new" id="closeButton5"  data-toggle="modal" data-target="#updateDept">Save</a>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Add dept modal --> */}
            <div className="modal fade" id="addDept" role="dialog" aria-hidden="true">
                <AddDepartment closedepartment={closedepartment}  />
                <a className="close-bt-new" id="closeButton6"  data-toggle="modal" data-target="#addDept">Save</a>
            </div>
            <div className="modal fade" id="deletedepartment" role="dialog" aria-hidden="true" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>

                <div className="modal-dialog ticket-modal customer-modal modal-dialog-centered" role="document" style={{ width: "450px" }}>
                    <div className="modal-content">
                        <div className="modal-body" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>

                            <h3 className="text-center"> Are you sure to delete this Department ?</h3>
                            <div className="row">


                                <div className="col-sm-12">
                                    <div className="form-group text-center ">
                                        <button className='deteletno mr-4' id='closeFun' data-toggle="modal" data-target="#deletedepartment">no</button>
                                        <button className='btn deteletyes w-20' onClick={deletedepartment}>yes</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DepartmentTab