import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const {REACT_APP_SERVER_URL}=process.env;
export const fetchAllCustomerMetaData  = createAsyncThunk(
  "filters/fetchAllCustomerMetaData ",
  async (params) => {
    const { data } = await axios.get(
      `${REACT_APP_SERVER_URL}/api/customer`,
      {
        headers: {"Authorization" : `Bearer ${params?.token}`},
      }
    );
    return data.data;
  }
);

export const metaAllCustomerSlice= createSlice({
  name: "filtercustomer",
  initialState: {
    customer: [],
    error: null,
  },
  
  reducers: {
    setCustomerDetails: (state, action) => {
      state.CustomerDetails = action.payload;
  },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCustomerMetaData .pending, (state) => {
      })
      .addCase(fetchAllCustomerMetaData .fulfilled, (state, action) => {
        state.customer = action.payload.data;
        state.customer_option = action.payload.data.map((obj) => {
          return {
            value: obj.id,
            label: obj.name
          };
        });        

          
      })
      .addCase(fetchAllCustomerMetaData .rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase("persist/REHYDRATE", (state, action) => {
        state.customer_list = [];
        state.error = null;
      });
  },
});

export const {
  setCustomerDetails
} = metaAllCustomerSlice.actions;
export default metaAllCustomerSlice.reducer;