
import React, { useEffect, useRef, useState } from 'react'
import calender from '../../assets/images/calender.svg'
import $ from 'jquery';
import Select from "react-select";
import 'select2';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import AddCustomer from '../Customer/AddCustomer';
import Datetime from 'react-datetime'
import "react-datetime/css/react-datetime.css";
import { ErrorMessage } from '@hookform/error-message';
import { ToastContainer, toast } from 'react-toastify';
import '../../assets/css/style.css';
import '../../assets/css/resp.css';
import { useParams } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { fetchMetaDatas } from "../../redux/Metalist";
import { fetchCustometTicketDatas } from "../../redux/CustomerTicketListSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchTicketbyCustomer } from '../../redux/TicketbyCustList';
import moment from 'moment';

const { REACT_APP_AUTH_SERVER } = process.env;
const { REACT_APP_SERVER_URL } = process.env;
const AddcustomerTicket = ({ closeTicket, cusId }) => {
    const dispatch = useDispatch();
    const [loading, setloading] = useState(false);
    const token = localStorage.getItem("token")
    const cusid = useParams();
    const { meta_list, prioArray, staArray, typeArray, asignArray, labArray, productArray, productId,checkproduct } = useSelector((state) => state.metalist);
    const { customer } = useSelector((state) => state.allcustomers);
    const [startDate, setStartDate] = useState(new Date());
    const [assignedto, setassignedto] = useState([]);
    const [productname, setproductname] = useState([]);
    const [notifyto, setnotifyto] = useState([]);
    const [priority, setpriority] = useState([]);
    const [status, setstatus] = useState([]);
    const [label, setlabel] = useState([]);
    const [type, settype] = useState([]);
    const [chekcproduct, setchekcproduct] = useState([]);
    
    const [productid, setproductid] = useState();
    const [selectedImage, setSelectedImage] = useState(null);
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        control,
        setValue,
        setFocus,
        reset,
        watch,
        setError,
        clearErrors,
        values,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
useEffect(() => {
    reset({
        'issue': '',
        'description': '',
        'productname': '',
       
        'priority': '',
        'assignedto': '',
        'notifyto': '',
        'status': '',
        'type': '',
        'label': '',
        'image': ''
    });
    setproductname('')
    setpriority('')
    setassignedto('')
    setnotifyto('')
    setstatus('')
    settype('')
    setlabel('') 
}, [])


    const handleAssignedto = (value) => {
        setValue("assignedto", value)
        setassignedto(value)
    }
    const handleNotifyto = (value) => {
        setnotifyto(value)
        setValue("notifyto", value)
    }
    const handleProduct = (value) => {
        setValue("productname", value)
        setproductname(value)
        // console.log();
    }
    const handlepriority = (value) => {
        setValue("priority", value.value)
        setpriority(value)
       
    }
    const handlestatus = (value) => {
        setValue("status", value.value)
        setstatus(value)
       
    }
    const handlelabel = (value) => {
        setValue("label", value.value)
        setlabel(value)
       
    }
    const handletype = (value) => {
        setValue("type", value.value)
        settype(value)
       
    }


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);
    };
    const Createticket = async (e) => {
        setloading(true)
        // var modal = document.getElementById('ticketModal');
        // var myModal = new bootstrap.Modal(modal);

        let assign = assignedto.map((val) => {
            return (
                parseInt(val.value)
            )
        })
        let notify = notifyto.map((val) => {
            return (
                parseInt(val.value)
            )
        })
        let productlist = productname?.map((val)=>{
            return(
                parseInt(val.value)
            )
        })
        const entry = {
            [productId]: productlist
        };

        const formatted = startDate.toISOString().slice(0, 19).replace('T', ' ');
        const dataxx= {
            additional_field_values: [entry],
            assigned_to: assign,
            customer_id: parseInt(cusId),
            description: getValues().description,
            due_date: formatted,
            image: getValues()?.image?getValues().image[0]:[],
            issue: getValues().issue,
            notify_to: notify,
            priority: parseInt(getValues().priority),
            status: parseInt(getValues().status),
            ticket_type: parseInt(getValues().type),
            ticket_type: parseInt(getValues().type),
            ticket_label: parseInt(getValues().label),
        }
        
        await axios({

            url: `${REACT_APP_SERVER_URL}/api/tickets`,
            method: "POST",
            headers: { "Authorization": `Bearer ${token}` },
            data: {
                additional_field_values: [entry],
                assigned_to: assign,
                customer_id: parseInt(cusId),
                description: getValues().description,
                due_date: formatted,
                image: getValues()?.image?getValues().image[0]:[],
                issue: getValues().issue,
                notify_to: notify,
                priority: parseInt(getValues().priority),
                status: parseInt(getValues().status),
                ticket_type: parseInt(getValues().type),
                ticket_type: parseInt(getValues().type),
                ticket_label: parseInt(getValues().label),
            }
        }).then((res) => {

            if (res.data.success == true) {
                toast.success(res.data.message);
                setloading(false)
                reset({
                    'issue': '',
                    'description': '',
                    'productname': '',
                   
                    'priority': '',
                    'assignedto': '',
                    'notifyto': '',
                    'status': '',
                    'type': '',
                    'label': '',
                    'image': ''
                });
                setproductname('')
                setpriority('')
                setassignedto('')
                setnotifyto('')
                setstatus('')
                settype('')
                setlabel('') 
            }
            else {
                toast.error(res.data.message)
                setloading(false)
            }
            dispatch(fetchCustometTicketDatas({ token: token, current_page: 1 }))
            dispatch(fetchTicketbyCustomer({token:token,id:cusId}))
            document.getElementById("cancelbtn").click()
            closeTicket()
        }).catch((err) => {
        })
        setloading(false)
    }
    const minDate = moment(); // Define the minimum date

    const isValidDate = current => {
        return current.isAfter(minDate, 'day'); // Check if the selected date is after the minimum date
    };
    const handlevalidation=()=>{
        clearErrors('issue');
        clearErrors('description');
        clearErrors('productname');
        clearErrors('priority');
        clearErrors('assignedto');
        clearErrors('notifyto');
        clearErrors('type');
        clearErrors('status');
        clearErrors('label');
       
    }
    return (
        <>
            <Loader loading={loading} />
            <form onSubmit={handleSubmit(Createticket)}>
                <div className="modal-dialog modal-dialog-lg ticket-modal modal-left" role="document" >
                    <div className="modal-content datefield">
                        <div className="modal-header">

                            <button type="button" className="close" data-toggle="modal" onClick={handlevalidation} data-target="#customerticketModal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body" style={{ padding:" 2px 40px 1px"}}>
                            <div className="add-ticket-form" >
                                <h3 className='newticket'>Create new Ticket</h3>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label for="">Issue</label>

                                            <input
                                                {...register("issue", {
                                                    required: true,
                                                })}
                                                className="form-control" id="" aria-describedby="" placeholder="Website development"
                                                type="text"
                                                name='issue'
                                            />
                                            {errors.issue?.type == "required" && (
                                                <span className="error">Issue is required</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label for="">Description</label>
                                            <textarea className="form-control"
                                                name='description'
                                                {...register("description", {
                                                    required: true,
                                                })} placeholder="Add a more detailed description…"></textarea>
                                            {errors.description?.type === "required" && (
                                                <span className="error">Description is required</span>)}
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                    <div className="form-group">
                                            <label for="">Product name</label>
                                            {checkproduct?.selection === "single" ? <>
                                                <select className="form-control"
                                                    name='productname'
                                                    {...register("productname", {
                                                        required: checkproduct?.mandatory,
                                                    })}
                                                    onChange={(value) => handleProduct(value.target.value)}
                                                >
                                                    <option value=''>--Select--</option>
                                                    {productArray.map((cus) => {
                                                        return (
                                                            <option value={cus.value}>{cus.label}</option>
                                                        )
                                                    })}

                                                </select>
                                                {errors.productname?.type === "required" && (
                                                    <span className="error">Product name is required</span>)}
                                            </> : <>
                                                <Select isMulti
                                                    {...register("productname", {
                                                        required: checkproduct?.mandatory,
                                                    })}
                                                    name='productname'
                                                    options={productArray}
                                                    value={productname}
                                                    placeholder="--Select--"
                                                    onChange={(value) => handleProduct(value)}
                                                />
                                                {productname?.length === 0 && <ErrorMessage
                                                    errors={errors}
                                                    name="productname"
                                                    render={({ message }) => <span className="error">Product name is required</span>}
                                                />}
                                            </>}

                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label for="">Priority</label>
                                            <Select
                                                {...register("priority", {
                                                    required: true,
                                                })}
                                                name='priority'
                                                options={prioArray}
                                                value={priority}
                                                placeholder="--Select--"
                                                onChange={(value) => handlepriority(value)}
                                            />
                                            {priority?.length === 0 && <ErrorMessage
                                                    errors={errors}
                                                    name="priority"
                                                    render={({ message }) => <span className="error">Priority is required</span>}
                                                />}
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label>Assigned to</label>
                                            {/* <select placeholder=""  ref={selectRef}>
                                            <option>High</option>
                                            <option>low</option>
                                            <option>medium</option>
                                        </select> */}
                                           <Select isMulti
                                                {...register("assignedto", {
                                                    required: "Assigned to is required",
                                                })}
                                                name='assignedto'
                                                options={asignArray}
                                                value={assignedto}
                                                placeholder="--Select--"
                                                onChange={(value) => handleAssignedto(value)}
                                            />
                                            {assignedto?.length === 0 && <ErrorMessage
                                                errors={errors}
                                                name="assignedto"
                                                render={({ message }) => <span className="error">{message}</span>}
                                            />}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label>Notify to</label>
                                            {/* <select placeholder=""  ref={selectRef}>
                                            <option>High</option>
                                            <option>low</option>
                                            <option>medium</option>
                                        </select> */}
                                            <Select isMulti
                                                name='notifyto'
                                                {...register("notifyto", {
                                                    required: "Notify to is required",
                                                })}
                                                options={asignArray}
                                                value={notifyto}
                                                placeholder="--Select--"
                                                onChange={(value) => handleNotifyto(value)}
                                            />
                                            {notifyto.length === 0 && <ErrorMessage
                                                errors={errors}
                                                name="notifyto"
                                                render={({ message }) => <span className="error">{message}</span>}
                                            />}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label>Status</label>

                                            <Select
                                                {...register("status", {
                                                    required: true,

                                                })}

                                                name='status'
                                                options={staArray}
                                                value={status}
                                                placeholder="--Select--"
                                                onChange={(value) => handlestatus(value)}
                                            />
                                           {status?.length === 0 && <ErrorMessage
                                                    errors={errors}
                                                    name="status"
                                                    render={({ message }) => <span className="error">Status is required</span>}
                                                />}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label>Type</label>
                                            <Select
                                                {...register("type", {
                                                    required: true,
                                                })}
                                                name='type'
                                                options={typeArray}
                                                value={type}
                                                placeholder="--Select--"
                                                onChange={(value) => handletype(value)}
                                            />
                                             {type?.length === 0 && <ErrorMessage
                                                    errors={errors}
                                                    name="type"
                                                    render={({ message }) => <span className="error">Type is required</span>}
                                                />}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label>Label</label>
                                            <Select
                                                {...register("label", {
                                                    required: true,
                                                })}
                                                name='label'
                                                options={labArray}
                                                value={label}
                                                placeholder="--Select--"
                                                onChange={(value) => handlelabel(value)}
                                            />
                                            {label?.length === 0 && <ErrorMessage
                                                    errors={errors}
                                                    name="label"
                                                    render={({ message }) => <span className="error">Label is required</span>}
                                                />}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label>Due on</label>
                                            <div className="datepicker date input-group">
                                                {/* <input type="date" placeholder="Choose Date" className="form-control" id="fecha1" /> */}
                                                <Datetime
                                                    isValidDate={isValidDate}
                                                    name='date'
                                                    value={startDate} onChange={(date) => setStartDate(date)} />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <img src={calender} alt="GetLead" />
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        {/* <div className="form-group"> */}
                                        <label>Image</label>
                                        {/* <div className="datepicker date input-group"> */}
                                        <input type="file" accept="image/*"
                                            // onChange={handleFileChange} 
                                            {...register("image")}
                                            name='image'
                                            className="form-control" id="fecha1"

                                        />
                                        {selectedImage && (
                                            <img src={URL.createObjectURL(selectedImage)} alt="Selected" />
                                        )}
                                        {/* </div> */}

                                        {/* </div> */}
                                    </div>
                                    {/* <div className="col-lg-6 col-md-12 col-sm-12">
                                                                <div className="form-group">
                                                                    <label>Time</label>
                                                                    <div className="input-group date timepicker" id="timePicker" >
                                                                        <input type="time"
                                                                         {...register("time", {
                                                                            required: true,
                                                                        })}
                                                                        className="form-control timepicker" id="timePicker"  />
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="add-ticket">Add Ticket</button>

                            <button id='cancelbtn'onClick={handlevalidation} type='button' className="cancel-btn" data-toggle="modal" data-target="#customerticketModal">Cancel</button>
                        </div>
                    </div>

                </div>
            </form>
            <div className="modal fade" id="addCustomer" role="dialog" aria-hidden="true">

                {/* <AddCustomer onclick={getCustomer}/> */}
            </div>

            {/* <ToastContainer/> */}
        </>
    )
}

export default AddcustomerTicket