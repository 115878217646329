import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const { REACT_APP_SERVER_URL } = process.env;
const ChangePassword = ({email}) => {
    let token = localStorage.getItem("token")
    const [show1, setshow1] = useState(true)
    const [show2, setshow2] = useState(true)
    const [show3, setshow3] = useState(true)
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        setValues,
        setFocus,
        values,
        reset,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const ChangePassword =()=>{
        axios({
            url:`${REACT_APP_SERVER_URL}/api/change-password`,
            method:'POST',
            headers: { "Authorization": `Bearer ${token}` },
            data:{
                current_password:getValues().oldpassword,
                new_password: getValues().password,
                confirm_password:getValues().confirmpasswords,
                email: email
            }
        }).then((res)=>{
            if(res.data.success){
                toast.success(res.data.message)
            }
            else{
                toast.error(res.data.message)
            }
        }).catch((err)=>{

        })
    }
    
  return (
    <div className="tab-pane fade" id="password" role="tabpanel" aria-labelledby="password-tab">
    <div className="profile-form">
        <form className="pt-4" onSubmit={handleSubmit(ChangePassword)}>
            <div className="form-group">
                <label>Current password</label>
                <div className="input-group" id="">
                    <input className="form-control" 
                    type={show1?"password":"type" }
                    {...register("oldpassword", {
                        //   required: true,

                        required: "Password is required",
                    
                        // 
                       
                    })}/>
                    {/* <div className="input-group-addon d-flex align-items-center show_hide_password hide-password">
                        <a href="#"  onClick={()=>setshow1(!show1)}>{show1?<i className="fa fa-eye-slash" aria-hidden="true"></i>
                        :<i className="fa fa-eye " aria-hidden="true"></i>}</a>
                    </div> */}
                </div>
                    {errors.oldpassword && <span className="error">{errors.oldpassword.message}</span>}
            </div>
            <div className="form-group">
                <label>New password</label>
                <div className="input-group" id="">
                    <input className="form-control"
                    type={show2?"password":"type" }
                    {...register("password", {
                        //   required: true,

                        required: "New password is required",
                        minLength: {
                            value: 7,
                            message: "Password must have at least 7 characters."
                        },
                        maxLength: {
                           value: 15,
                           message: "Password must be less than 15 characters."
                           },
                        validate: (value) => {
                            return (
                                [/[a-z]/, /[A-Z]/, /[0-9]/, /[^a-zA-Z0-9]/,/[@#$%^&+=]/].every((pattern) =>
                                    pattern.test(value)
                                ) || "Must include lower, upper, number, and special chars."
                            );

                        },
                        // 
                       
                    })} />
                    <div className="input-group-addon d-flex align-items-center show_hide_password hide-password">
                        <a href="#"  onClick={()=>setshow2(!show2)}>{show2?<i className="fa fa-eye-slash" aria-hidden="true"></i>
                        :<i className="fa fa-eye " aria-hidden="true"></i>}</a>
                    </div>
                </div>
                    {errors.password && <span className="error">{errors.password.message}</span>}
                                                            {/* {errors.password?.type == "required" &&
                                                             <span className="error">{errors.password.message}</span>} */}
                                                            {errors.password?.type == "validate1" && (
                                                                <span className="error">{errors.password.message}</span>
                                                            )}
            </div>
          
            <div className="form-group">
                <label for="">Re-enter new password</label>
                <div className="input-group" id="">
                    <input className="form-control" type={show3?"password":"type" }
                     {...register("confirmpasswords", {
                        //   required: true,

                        required: "Confirm password is required",
                        validate: (value) => {
                            const { password } = getValues();
                            return password == value || "Password does not match";
                        }

                    })} />
                     <div className="input-group-addon d-flex align-items-center show_hide_password hide-password">
                        <a href="#"  onClick={()=>setshow3(!show3)}>{show3?<i className="fa fa-eye-slash" aria-hidden="true"></i>
                        :<i className="fa fa-eye " aria-hidden="true"></i>}</a>
                    </div>
                </div>
                     {errors.confirmpasswords && <span className="error">
                         {errors.confirmpasswords.message}</span>}
            </div>
            <div className="form-group">
                <button type='submit'>Update</button>
            </div>
        </form>
    </div>
</div>

  )
}

export default ChangePassword