import React, { useState } from 'react'
import editvector from '../../assets/images/edit-vector.svg';
import deleteicon from '../../assets/images/delete.svg';
import history from '../../assets/images/history.svg';
import tick from '../../assets/images/tick-icon.svg';
import cale_vector from '../../assets/images/cale-vector.svg';
import { useEffect } from 'react';
import { fetchActivityList } from '../../redux/ActicityListing';
import { fetchTicketdetailDatas } from "../../redux/Ticketdetailist";
import {fetchTaskCategoryDatas} from '../../redux/TaskCategory'
import { useDispatch, useSelector } from 'react-redux';
import UpdateLog from '../Log/UpdateLog';
import UpdateTask from '../Task/UpdateTask';
import Pagination from "@mui/material/Pagination";
import UpdateActivityTicket from '../Ticket/UpdateActivityTicket';
import { toggleId  } from '../../redux/selectedIdsSlice';
import { clearSelectedIds  } from '../../redux/selectedIdsSlice';
import CircularProgress from "@mui/material/CircularProgress";
import { fetchlogdetailDatas } from '../../redux/LogDetails';

function ActivityTab({id}) {
    const dispatch = useDispatch();
    const { logtypelist } = useSelector((state) => state.logtypelist);
    const {task_category} = useSelector((state) => state.taskcategory)
    const selectedIds = useSelector((state) => state.selectedIds);
    // console.log(selectedIds,'filterLogType_list');
    const token = localStorage.getItem("token")
    const [taskid, settaskid] = useState();
    const [logid, setlogid] = useState();
    const [ticketid, setticketid] = useState();
    const [page, setPage] = useState(1);

    const handleupdatetask = (id) => {
       
        settaskid(id)
    }
    const handleupdatelog = (id) => {
    //    console.log(id);
        setlogid(id)
        dispatch(fetchlogdetailDatas({token:token,id:id}))
    }
    const handleupdateticket = (id) => {
      
        dispatch(fetchTicketdetailDatas({token:token,ticketid:id}))
        setticketid(id)
    }

    const changePage = (e,value) => {
        // console.log(e,value);
        setPage(value) 
        dispatch(fetchActivityList({token:token,ticketid:id,page:e,per_page:5}))
    };
    const { activity_list,activity_list_pagination,loading } = useSelector((state) => state.activityList);
    const { detail_list } = useSelector((state) => state.detaillist);


    const handleCheckboxClick = (event) => {
        const clickedId = event.target.id;
        dispatch(toggleId(clickedId));
        const Id= () => {
            if (selectedIds.includes(clickedId)) {
              return selectedIds.filter((id) => id !== clickedId);
            } else {
              return [...selectedIds, clickedId];
            }
          }
        //   console.log(Id(),'op');
        setTimeout(() => {
            dispatch(
              fetchActivityList({
                token: token,
                ticketid: id,
                log: Id(),
                task: [],
                page: 1,
                per_page: 5,
              })
            );
          }, 3000);
    };
    const [selectedtaskIds, setSelectedtaskIds] = useState([]);

    const handleTaskCheckboxClick = (event) => {
      const clickedId = event.target.id;
      if (selectedtaskIds?.includes(clickedId)) {
        setSelectedtaskIds(selectedtaskIds.filter((id) => id !== clickedId));
      } else {
        setSelectedtaskIds([...selectedtaskIds, clickedId]);
      }
    };
    // console.log(selectedIds,'selectedIdsselectedIds');
    useEffect(() => {
        if (selectedIds) {
           
            // console.log('ajith');
        }
        
      }, [selectedIds]);
     const clearFunction=(e)=>{
        dispatch(clearSelectedIds())
        const parentElement = e.target.closest(".dropdown");
        //alert(parentElement);
        parentElement.classList.toggle('dropdown-show1');
     }
    return (
        
        <div className="activity-col">
            <div className="acti-head d-flex align-items-center justify-content-between pb-5">
                <div>
                    <h6>Activities</h6>
                </div>
                <div className="dropdown show">
                    <a
                        className="dropdown-toggle d-flex align-items-center"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        
                        // aria-haspopup="true"
                        // aria-expanded="false"
                        //onClick={(e) =>clearFunction}
                        onClick={(e) => {
                            // e.preventDefault();
                            clearFunction(e)
                        }}
                    >
                        FilterBy: &nbsp;<span>All activity &nbsp;</span><i className="fa fa-regular fa-chevron-down" style={{ color: "#808080" }}></i>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <div className="activity-filter">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="filters">
                                        <h6>Log</h6>
                                        {logtypelist?.map((obj)=><div className="">
                                            <label className="container-checkbox" key={obj.id}>
                                                {obj.type}
                                                <input onClick={handleCheckboxClick} type="checkbox" id={obj.id} className="checkmark"/>
                                            </label>
                                        </div>)}
                                        
                                       
                                        
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="filters">
                                        <h6>Task</h6>
                                        {task_category?.map((obj)=><div className="">
                                            <label className="container-checkbox" key={obj.id}>
                                                {obj.category}
                                                <input type="checkbox" onClick={handleTaskCheckboxClick} id={obj.id} className="checkmark"/>
                                            </label>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="activity-listing">
                {loading? <div style={{width:'100%',height:'300px',display:'flex',justifyContent:'center',alignItems:'center'}}><CircularProgress color="inherit" /></div>:
                    activity_list?.map((act)=>{
                        return(


                <li className="single-list d-flex align-items-baseline">
                    <div className="acti-icon" style={{ backgroundColor: "#ff606d" }}>
                        <i className="fa fa-file-text"></i>
                    </div>
                    <div className="timeline-row d-flex">
                        {act.created_by?
                        <div className="timeline-icon">
                            <p style={{textTransform:"uppercase"}}>{act.created_by?.charAt(0)}</p>
                        </div>:
                        <div className="timeline-icon-trance">
                           
                        </div>
                        }
                        <div className="timeline-contents">
                            <div className="timeline-header">
                                <div className="t-header-contents d-flex justify-content-between">
                                    <div className="header-contents">
                                        {/* <p><span className="name">Getlead demo</span> created a <span className="task">Call task</span> for <span className="task-for">Amjith</span></p> */}
                                        <p style={{textTransform:"capitalize"}}><span className="name">{act.title}</span></p>
                                        <p className="date">{act.created_at}</p>
                                    </div>
                                    {act.can_be_updated==1?
                                  <>
                                   {act.type === "ticket" && (
                                      <div className="header-icons d-flex align-items-center">
                                      <div className="">
                                          {/* <a href="#"> <img src={history} alt="Getlead" /> </a> */}
                                      </div>
                                      <div className="" data-toggle="modal" data-target="#updateticketModal">
                                          <a href="#" onClick={()=>handleupdateticket(act.ticket_id)}> <img src={editvector} alt="Getlead" /> </a>
                                      </div>
                                  </div>
                                    )}
                                   {act.type === "log" && (
                                      <div className="header-icons d-flex align-items-center">
                                      <div className="">
                                          {/* <a href="#"> <img src={history} alt="Getlead" /> </a> */}
                                      </div>
                                      <div className="" data-toggle="modal" data-target="#updatelogModal">
                                          <a href="#" onClick={()=>handleupdatelog(act.log_id)}> <img src={editvector} alt="Getlead" /> </a>
                                      </div>
                                  </div>
                                    )}
                                   {act.type === "task" && (
                                      <div className="header-icons d-flex align-items-center">
                                      <div className="">
                                          {/* <a href="#"> <img src={history} alt="Getlead" /> </a> */}
                                      </div>
                                      <div className="" data-toggle="modal" data-target="#updatetaskModal">
                                          <a href="#" onClick={()=>handleupdatetask(act.task_id)}> <img src={editvector} alt="Getlead"  /> </a>
                                      </div>
                                  </div>
                                    )}
                                  </>
                                     :""  } 
                                </div>
                                {
                                    act.status=="Closed"?
                                    <div className="timeline-para">
                                    <p className="task-done">
                                        <span className="pr-1"><img src={tick} alt="Getlead" /> 
                                        </span>{act.description}
                                    </p>
                                </div>:
                                <div className="timeline-para">
                                    <p>
                                        <span>{act.description}</span>
                                    </p>
                                </div>
                                }
                                {
                                    act.status=="Closed"?
                                <div className="timeline-status d-flex align-items-center">
                                    <div className="sch-date d-flex align-items-center">
                                        <img src={cale_vector} alt="Getlead" />
                                        <p>&nbsp;Scheduled Date: <span>October 12 at 4:15 pm</span></p>
                                    </div>
                                    <div className="sch-completed">
                                        <p>Status: <span>{act.status}</span></p>
                                    </div>
                                </div>:
                                " "}
                            </div>
                        </div>
                    </div>
                </li>
                        )
                    })
                }
            </ul>
            {activity_list_pagination?.total>0?
            
            <Pagination
                count={activity_list_pagination?.total_pages}
                color="primary"
                variant="outlined"
                onChange={(e, value) => changePage(value)}
                page={activity_list_pagination?.current_page}
                style={{float:"right"}}

            />
        :""}
            <UpdateLog ticketid={id} logId= {logid}/>
            <div className="modal fade add-activity-modal" id="updatetaskModal" role="dialog" aria-hidden="true" data-backdrop="false" data-keyboard="true">
                 <UpdateTask ticketid={id} taskid={taskid} /> 
            </div>
            <div className="modal fade add-activity-modal" id="updateticketModal" role="dialog" aria-hidden="true" data-backdrop="false" data-keyboard="true">
            <UpdateActivityTicket/>
            </div>
           
        </div>
    )
}

export default ActivityTab