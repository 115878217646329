import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import $ from 'jquery'
import { fetchUserdeparment } from '../../redux/UserDepartment';
const { REACT_APP_SERVER_URL } = process.env;
const AddDepartment = () => {
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        setValues,
        setFocus,
        values,
        reset,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    useEffect(() => {
        setassignedto('')
        setdepartmentname('')
    }, [])

    const token = localStorage.getItem("token")
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { asignArray } = useSelector((state) => state.metalist);///2///
    const [loading, setloading] = useState(false);
    const [assignedto, setassignedto] = useState([]);
    const [departmentname, setdepartmentname] = useState([]);
    const handleAssignedto = (value) => {
        // setValue("assignedto",value)
        setassignedto(value)
    }
    const createdepartment = () => {
        let assign = assignedto.map((val) => {
            return (
                parseInt(val.value)
            )
        })
        axios({
            url: `${REACT_APP_SERVER_URL}/api/department`,
            method: 'POST',
            headers: { "Authorization": `Bearer ${token}` },
            data: {
                department: departmentname,
                agent: assign
            }
        }).then((res) => {
            // console.log(res.data, "success");
            if (res.data.success) {
                toast.success(res.data.message)

                dispatch(fetchUserdeparment(token, page))
                setloading(false)
                setassignedto('')
                setdepartmentname('')
                const addCustomerModal = document.getElementById('closeButton6');
                addCustomerModal.click();
                // addCustomerModal.style.display = 'none';
            }
            else {

                toast.error(res.data.data.email[0])
                setloading(false)
            }
        }).catch((err) => {

            if (err.request) {

                console.log(err.request)
            } if (err.response) {
                // console.log(err.response,'jjjjjjjjjjjj')
                toast.error(err.response.data.data.email[0])
                setloading(false)
            }
        })

    }
    const closescreen = () => {
        const addCustomerModal = document.getElementById('addDept');
        addCustomerModal.style.display = 'none';
        document.addEventListener('DOMContentLoaded', function () {
            // Find the element with the data-dismiss attribute and add a click event listener to it
            document.querySelector('[data-dismiss="modal"]').addEventListener('click', function () {
                // Close the modal programmatically using Bootstrap's method
                $('#addDept').modal('hide');
                // var x =  document.getElementsByClassName('modal-backdrop');
                // alert(x);
                //document.getElementsByClassName('modal-backdrop').remove()
            });
        });
    }

    return (
        <div className="modal-dialog ticket-modal customer-modal modal-dialog-centered" role="document">
            <div className="modal-content  add-department">
                <div className="modal-body" style={{ padding: "60px 40px 30px" }}>
                    <form className="add-ticket-form" onSubmit={handleSubmit(createdepartment)}>
                        <h3 className="text-center">Add department</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label for="">Department name</label>
                                    <input type="text" className="form-control"
                                        value={departmentname}
                                        maxLength={50}
                                        onChange={(e) => setdepartmentname(e.target.value)}
                                        required
                                        id="" aria-describedby="" placeholder="Enter department name" />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label for="">Add agents</label>
                                    <Select isMulti
                                        // {...register("assignedto", {
                                        //     required: "Assigned to is required",
                                        // })}
                                        name='assignedto'
                                        required
                                        options={asignArray}
                                        value={assignedto}
                                        placeholder="--Select--"
                                        onChange={(value) => handleAssignedto(value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group text-center mt-4">
                                    <button type="submit" className="add-ticket" style={{ width: "85%", marginTop: "20px" }}>Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddDepartment