import React, { useEffect, useState } from 'react'
import { ErrorMessage } from '@hookform/error-message';
import { ToastContainer, toast } from 'react-toastify';
import calender from '../../assets/images/calender.svg'
import Datetime from 'react-datetime'
import "react-datetime/css/react-datetime.css";
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { fetchLogListDatas } from '../../redux/LogList';
import { useDispatch, useSelector } from 'react-redux';
const { REACT_APP_SERVER_URL } = process.env;
const Addlog = (id) => {
    const token = localStorage.getItem("token")
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        control,
        setValue,
        setFocus,
        reset,
        watch,
        setError,
        clearErrors,
        values,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    useEffect(() => {
        // getlogtype()
    }, [])

    const dispatch = useDispatch();
    const [logstartDate, setlogStartDate] = useState(new Date());
    const [outcomelist, setoutcomelist] = useState([])
    const [logid, setlogid] = useState()
    const {  logtypelist} = useSelector((state) => state.logtypelist);
   
    
    const handlelogtype = async (event) => {
        setValue("logtype", event.target.value)
        setlogid(event.target.value)
        getoutcome(event.target.value)
    }
    const getoutcome = async (id) => {
        await axios({
            url: `${REACT_APP_SERVER_URL}/api/outcomes?type_id=${id}`,
            method: "GET",
            headers: { "Authorization": `Bearer ${token}` },
        }).then((res) => {
            // console.log(res.data.data,"out");
            setoutcomelist(res.data.data)
        }).catch((err) => {
        })
    }
    const createlog = async () => {
        const logformatted = logstartDate.toISOString().slice(0, 19).replace('T', ' ');
        // console.log(
        //     "ticket_id", id,
        //     "outcome_id", parseInt(getValues().outcome),
        //     "type_id", parseInt(getValues().logtype),
        //     "date_time", logformatted,
        //     "description", getValues().logdes,
        // );
        await axios({
            url: `${REACT_APP_SERVER_URL}/api/logs`,
            method: "POST",
            headers: { "Authorization": `Bearer ${token}` },
            data: {
                ticket_id: id.id,
                outcome_id: parseInt(getValues().outcome),
                type_id: parseInt(getValues().logtype),
                date_time: logformatted,
                description: getValues().logdes,
            }

        }).then((res) => {
            // console.log(res.data);
            if (res.data.success == true) {
                toast.success(res.data.message)
                reset({
                    'outcome': '',
                    'logtype': '',
                    'date_time': '',
                    'logdes': '',
                   
                });
                Closemodal()
                dispatch(fetchLogListDatas({token:token,ticketid:id.id}))
            }
            else {
                toast.error(res.data.message)
            }
        }).catch((err) => {
        })
    }
    const Closemodal =()=>{
        clearErrors('logtype');
        clearErrors('outcome');
        clearErrors('logdes');
        const addCustomerModal = document.getElementById('logModal');
                addCustomerModal.style.display = 'none';
    }
    return (

        <div class="modal fade add-activity-modal" id="logModal" role="dialog" aria-hidden="true" data-backdrop="false" data-keyboard="true">
        <div className="modal-dialog modal-dialog-lg ticket-modal 
                                        customer-modal log-modal" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={Closemodal}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body pt-1">
                        <form className="add-ticket-form" onSubmit={handleSubmit(createlog)}>
                            <h3>Add Log note</h3>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label for="">Log a </label>
                                        <select className="form-control"
                                            {...register("logtype", {
                                                required: "Log type is required"
                                            })}
                                            onChange={(e) => handlelogtype(e)}
                                        >
                                            <option value="">select</option>
                                            {logtypelist?.map((log) => {
                                                return (
                                                    <option value={log.id}>{log.type}</option>
                                                )
                                            })}

                                        </select>
                                        {errors?.logtype && <span className="error"> {errors.logtype.message}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label for="">Outcome</label>
                                        <select className="form-control"
                                            {...register("outcome", {
                                                required: "Outcome is required"
                                            })}>
                                            <option value="">select</option>
                                            {outcomelist?.map((out) => {
                                                return (

                                                    <option value={out.id}>{out.outcome}</option>
                                                )
                                            })}
                                        </select>
                                        {errors?.outcome && <span className="error"> {errors.outcome.message}</span>}
                                        {/* {errors?.logtype && <span className="error"> {errors.logtype.message}</span>} */}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label for="">Due on</label>
                                        <div className="datepicker date input-group">
                                            {/* <input type="text" placeholder="Choose Date" className="form-control" id="fecha1" /> */}
                                            <Datetime

                                                name='date'
                                                value={logstartDate} onChange={(date) => setlogStartDate(date)} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <img src={calender} alt="GetLead" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-6 col-md-12 col-sm-12">
                                                                <div className="form-group">
                                                                    <label for="">Time</label>
                                                                    <input type="email" className="form-control" id="" aria-describedby="" />
                                                                </div>
                                                            </div> */}
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label for="">Description</label>
                                        <textarea className="form-control"
                                            {...register("logdes", {
                                                required: "Description is required"
                                            })}></textarea>
                                        {errors?.logdes && <span className="error"> {errors.logdes.message}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-btn">
                                        <button className="add-ticket w-100" type='submit'>Add</button>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-btn">
                                        <button
                                        type='reset'
                                            style={{ width: "fit-content", padding: "12px 50px" }}
                                            className="cancel-btn w-100" onClick={Closemodal}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            
        </div>

    )
}

export default Addlog