import React from 'react'
import homeicon from '../../assets/images/home-icon.svg'
import logo from '../../assets/images/logo-new.svg'
import getlead2 from '../../assets/images/Getlead-2.svg'
import playstore from '../../assets/images/playstore.svg'
import appstore from '../../assets/images/qns-icon.svg'
import qns from '../../assets/images/playstore.svg'
import profile2 from '../../assets/images/profile-2.svg'
import profile from '../../assets/images/profile-icon.svg'

const SetupTeams = () => {
  const  uname = localStorage.getItem("uname")
  return (
    <div>
         <div className="setup-teams-sec">
         <div className="team-sidebar">
            <div className="single-col">
               <a href="#" className="home-nav">
               <img src={homeicon} alt="getlead"/>
               </a>
            </div>
            <div className="setup-col">
               <div className="setup-logo">
                  <a href="#">
                  <img className="web-logo" src={logo} alt="Getlead"/>
                  <img className="mob-logo" src={getlead2} alt="getlead"/>
                  </a>
                  <p>7 days free trial</p>
               </div>
               <div className="setup-steps billing-col">
                  <div className="setup-single">
                     <p className="s-number">01</p>
                     <div className="bill-texts">
                        <h4>Setup your team</h4>
                        <p>Let’s build your workflow in few steps</p>
                     </div>
                  </div>
               </div>
               <div className="setup-steps">
                  <div className="setup-single">
                     <p className="s-number">02</p>
                     <div className="bill-texts">
                        <h4>Add Leads</h4>
                        <p>Adding leads is not 
                           difficult at all
                        </p>
                     </div>
                  </div>
               </div>
               <div className="setup-steps">
                  <div className="setup-single">
                     <p className="s-number">02</p>
                     <div className="bill-texts">
                        <h4>Finish setup</h4>
                        <p>Finish building your workflow in just a few steps</p>
                     </div>
                  </div>
               </div>
               <div className="bottom-download setup-steps">
                  <p>Download GetLead App</p>
                  <ul className="playstore-links">
                     <li>
                        <a href="#">
                        <img src={playstore} alt="getlead"/>
                        </a>
                     </li>
                     <li>
                        <a href="#">
                        <img src={appstore} alt="getlead"/>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div className="team-dash-sec">
            <div className="team-header">
               <div className="welcome-text">
                  <h3>Welcome {uname}</h3>
                  <p>Let’s build your workflow in few steps</p>
               </div>
               <div className="plan-col premium">
                  <p>Trial expires in <span>6 days </span></p>
                  <div className="premium-btn">
                     <a href="#">Upgrade to premium</a>
                  </div>
                  <ul className="header-icons">
                     <li>
                        <a href="#">
                        <img src={qns} alt="getlead"/>
                        </a>
                     </li>
                     <li>
                        <div className="dropdown show">
                           <a className=" dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                           <img style={{width:"40px",height:"40px"}} src={profile2} alt="getlead"/>
                           </a>
                           <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                              <a className="dropdown-item" href="#">Action</a>
                              <a className="dropdown-item" href="#">Another action</a>
                              <a className="dropdown-item" href="#">Something else here</a>
                           </div>
                        </div>
                     </li>
                  </ul>
               </div>
            </div>
            <div className="add-y-team">
               <div className="y-team-header">
                  <h3>Add your team</h3>
                  <p>To add new members in your team, sent them an invite first.</p>
               </div>
               <div className="agent-boxes">
                  <div className="agent-single">
                     <div className="agent-img">
                        <img src={profile} alt="getlead"/>
                     </div>
                     <div className="agent-details">
                        <p>Sharun das</p>
                        <a className="agent-email" href="mailto:Sharun@getlead.co.uk">Sharun@getlead.co.uk</a>
                        <div className="agent-btn-col">
                           <a className="agent-btn" href="#">Admin</a>
                        </div>
                     </div>
                  </div>
                  <div className="agent-single">
                     <div className="agent-img">
                        <img src={profile} alt="getlead"/>
                     </div>
                     <div className="agent-details">
                        <p>Sumisha</p>
                        <a className="agent-email" href="mailto:Sharun@getlead.co.uk">sumisha@getlead.co.uk</a>
                        <div className="agent-btn-col">
                           <a className="agent-btn" href="#">Agent</a>
                        </div>
                        <span>
                        Invitation send
                        </span>
                     </div>
                  </div>
                  <div className="agent-single agent-invite">
                     <p>Invite<br/> new team member</p>
                     <div className="invite-btn">
                        <a className="setup-btn" href="#" data-target="#invite-modal" data-toggle="modal">
                        Invite
                        </a>
                     </div>
                  </div>
               </div>
            </div>
            <div className="bottom-btns">
               <div className="back-btn">
                  <a href="#">Skip</a>
               </div>
               <div className="next-btn">
                  <a href="#">
                  Next
                  </a>
               </div>
            </div>
         </div>
      </div>
      {/* <!----------------------------------- Invite Modal -------------------------------------------> */}
      <div className="modal invite-modal fade" id="invite-modal" tabindex="-1"  data-toggle="modal" role="dialog" aria-labelledby="myModalLabel-task" aria-hidden="true">
         <div className="modal-dialog" role="document">
            <div className="modal-content">
               {/* <!-- <div className="modal-header" style="padding: 0px">
                  <div className="row" style="width: 100%; margin: 0">
                     <button type="button" className="close" id="closebutton" data-dismiss="modal" aria-label="Close" >
                     <img src="./images/modal-close.svg" alt="getlead"/>
                     </button>
                  </div>
                  </div> --> */}
               <div className="modal-body">
                  <div className="invitation-col">
                     <h5>Invite your team</h5>
                     <p>To get your team members in<br/> 
                        Getlead CRM add them
                     </p>
                     <form className="invite-form">
                        <input type="text" name="name" placeholder="Name"/>
                        <input type="email" name="email" placeholder="Email"/>
                        <select>
                           <option>Role</option>
                           <option>Role 2</option>
                           <option>Role 3</option>
                        </select>
                        <button className="setup-btn">Send invitation</button>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
    </div>
  )
}

export default SetupTeams