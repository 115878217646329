import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { fetchUserdeparment } from '../../redux/UserDepartment';

const { REACT_APP_SERVER_URL } = process.env;

const AddDesgnation = () => {
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        control,
        setValue,
        setFocus,
        reset,
        watch,
        setError,
        clearErrors,
        values,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });

    const token = localStorage.getItem("token")
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const addDesignation = () => {
    
        axios({
            url: `${REACT_APP_SERVER_URL}/api/designation`,
            data: {
                designation: designations
            },
            method: 'POST',
            headers: { "Authorization": `Bearer ${token}` },
        }).then((res) => {
            // console.log(
            //     res.data
            // );
            if (res.data.success) {
                toast.success(res.data.message)
                dispatch(fetchUserdeparment(token))
                setdesname('')
                // setDesignations([])
                const addCustomerModal = document.getElementById('closeButton7');
                addCustomerModal.click();
                // addCustomerModal.style.display = 'none';

            }
        }).catch((er) => {
            toast.error("error")
        })
    }
    const [designations, setDesignations] = useState(['']);
    const [desname, setdesname] = useState([]);

    const handleAddField = () => {
        const newDesignations = [...designations, ''];
        setDesignations(newDesignations);
    };

    const handleRemoveField = (index) => {
        const newDesignations = [...designations];
        newDesignations.splice(index, 1);
        setDesignations(newDesignations);
    };

    const handleDesignationChange = (index, value) => {
        const newDesignations = [...designations];
        newDesignations[index] = value;
        setDesignations(newDesignations);
        setdesname(newDesignations);
    };
    return (
        <div className="modal-dialog ticket-modal customer-modal modal-dialog-centered" role="document">
            <div className="modal-content add-department">
                <div className="modal-body" style={{ padding: "60px 40px 30px" }}>
                    <form className="add-ticket-form" onSubmit={handleSubmit(addDesignation)}>
                        <h3 className="text-center">Add designation</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label for="">Designation name<span className="asterisk">*</span></label>
                                    <div className="multi-field-wrapper">

                                        <div className="multi-fields">
                                            {designations?.map((desg, index) => (
                                                <div key={index} className="multi-field mobile-group d-flex align-items-center mb-3">
                                                    <input
                                                        type="text"
                                                        id={`mobile_code_${index}`}
                                                        maxLength={50}
                                                        className="form-control mobile_code"
                                                        value={desg}
                                                        required
                                                        onChange={(e) => handleDesignationChange(index, e.target.value)}
                                                        name="stuff[]"
                                                        placeholder="Enter designation"
                                                    />
                                                    <a type="button" className="add-dlt-btn add-field" style={{
                                                        border: "2px solid #ff555c", width: " 27px",
                                                        height: "24px"
                                                    }}><i className="fa fa-plus" aria-hidden="true" onClick={handleAddField} style={{ fontSize: "16px" }}></i></a>
                                                    {designations?.length > 1 && (
                                                        <a type="button" className="add-dlt-btn remove-field"
                                                            style={{
                                                                border: "2px solid #ff555c", width: " 27px",
                                                                height: "24px"
                                                            }}
                                                            onClick={() => handleRemoveField(index)}><i className="fa fa-minus" aria-hidden="true" style={{ fontSize: "16px" }}></i></a>
                                                    )}

                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group text-center mt-4">
                                    <button type="submit" className="add-ticket" style={{ width: "85%" }}>Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddDesgnation