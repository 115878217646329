import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserdeparment } from '../../redux/UserDepartment';
import { ToastContainer, toast } from 'react-toastify';


const { REACT_APP_AUTH_SERVER } = process.env;
const { REACT_APP_SERVER_URL } = process.env;
const UpdateUser = ({userid,page}) => {
// console.log(userid,"mmmmmmmmmmmmmmmmm");
        const {
            register,
            formState: { errors, isValid, isDirty },
            handleSubmit,
            getValues,
            setValue,
            setFocus,
            values,
            reset,
            trigger,
        } = useForm({
            mode: "onChange",
            reValidateMode: "onChange",
        });
    const { userdpt_list, userlist, departmentlist, designationlist, usercount } = useSelector((state) => state.userdepartment);
    const { userdetail } = useSelector((state) => state.userdetail);
    const token = localStorage.getItem("token")
    const [dept, setdept] = useState()
    const [desg, setdesg] = useState()
    const dispatch = useDispatch();
    // console.log(userdetail,"manjima");
    useEffect(() => {
     setValue(
        "user",{
            name:userdetail?.name,
            email:userdetail?.email,
            department:userdetail?.department,
            designation: userdetail?.designation,
            
        }
     )
     setdept(userdetail?.department)
     setdesg(userdetail?.designation)
     setmobile(userdetail?.phone)
    }, [userdetail])
    const UpdateUser=()=>{
       // let hasClicked = false;
        axios({
            url:`${REACT_APP_SERVER_URL}/api/update-profile`,
            method:"POST",
            headers: { "Authorization": `Bearer ${token}` },
            data:{
                name:getValues().user.name,
                user_id:userid,
                phone:mobile,
                designation_id:Number(desg),
                department_id:Number(dept),
                email:getValues().user.email?getValues().user.email:null
            }
        }).then((res)=>{
            // console.log(res.data);
            if(res.data.success){
                dispatch(fetchUserdeparment({token:token, page:page}))
                toast.success(res.data.message)
                closeM();
               // const addCustomerModal = document.getElementById('closeButton3');
                // if (!hasClicked) {
                //     hasClicked = true;
                //     addCustomerModal.click();
                //   }
                
                // addCustomerModal.style.display = 'none';
            }
        }).catch((err)=>{
            console.log(err);
        })
    }
    const closeM=()=>{
        const addCustomerModal = document.getElementById('closeButton3');
         //alert(addCustomerModal);
                //    // hasClicked = true;
             addCustomerModal.click();
         
    }
    const [mobile, setmobile] = useState()
    function preventOtherCharactersFromNumberInputcount(evt) {
        if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57 || evt.target.value.length == 10) {
            evt.preventDefault();
        }
    }
  return (
   
    <div className="modal-dialog modal-dialog-md t-invite-modal" role="document">
        <div className="modal-content">
         
            <div className="modal-body">
                <div className="invitation-col">
                    <h5>Update user</h5>
                    <p>
                        To get your team members in<br />
                        Getlead CRM add them
                    </p>
                    <form className="invite-form" onSubmit={handleSubmit(UpdateUser)}>

                        <input type="text"

                            autoComplete="off"
                            {...register("user.name", {
                                required: true,
                                maxLength: {
                                    value: 50,
                                    message: "Name must be less than 50 characters."
                                },
                            })}
                            className="form-control"
                            aria-describedby="emailHelp"
                            placeholder="Enter Name" />
                        {errors.name?.type === "required" && (
                            <span className="error">Name is required</span>
                        )}
                        {errors.name && <span className="error">{errors.name.message}</span>}

                        <input
                            {...register("user.email", {
                                
                                pattern:
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

                            })}

                            className="form-control"
                            aria-describedby="emailHelp"
                            placeholder="Enter Email" />
                        {/* {errors.email?.type === "required" && (
                            <span className="error">Email is required</span>
                        )} */}
                        {errors.user?.email?.type === "pattern" && (
                            <span className="error">Email is not valid</span>
                        )}
                      
                        <select
                           
                            className="form-control"
                            aria-describedby="emailHelp"
                            placeholder='Department'
                            value={dept}
                            onChange={(e)=>setdept(e.target.value)}
                            >
                            <option>--Select Department--</option>
                            {departmentlist?.map((des) => {
                                return (

                                    <option value={des?.id}>{des?.department}</option>
                                )
                            })}
                        </select>
                       
                      
                        <select
                           placeholder='Designation'
                            className="form-control"
                            aria-describedby="emailHelp"
                            value={desg}
                            onChange={(e)=>setdesg(e.target.value)}
                            >
                            <option>--Select Designation--</option>
                            {designationlist?.map((des) => {
                                return (

                                    <option value={des?.id}>{des?.designation}</option>
                                )
                            })}
                        </select>
                       
                        <input

                            placeholder="eg: 9447 752 786"
                            value={mobile}
                            onChange={(e) => setmobile(e.target.value)}
                            onKeyPress={preventOtherCharactersFromNumberInputcount}
                            className="form-control"
                            type="text"
                            maxLength={10}
                        />
                        <button className="setup-btn" type='submit'>Update</button>
                        
                    </form>
                </div>
            </div>
        </div>
    </div>


  )
}

export default UpdateUser