import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import mataReducer from "./Metalist";
import customerReducer from "./Customerlist";
import detailReducer from './Ticketdetailist';
import ticketReducer from './TicketListSlice';
import ticketAssignReducer from './TikectlistAssign';
import allcustomerReducer from './Customermetadata';
import tasklistReducer from './TaskList';
import customerTicketsReducer from './CustomerTicketListSlice';
import userReducer from './UserDepartment'
import loglistReducer from './LogList'
import ticketbycustomerReducer from './TicketbyCustList'
import deparmentReducer from './Departmentlist'
import designationReducer from './Designationlist'
import activityReducer from './ActicityListing'
import taskcategoryReducer from './TaskCategory'
import taskstatusReducer from './TaskStatus'
import profiledetailsReducer from './UpdateProfile'
import logtypeReducer from './LogTypeList'
import reportReducer from './TicketReport'
import userdetailReducer from './UserDetails'
import selectedIdsReducer from './selectedIdsSlice';
import logdetailReducer from './LogDetails'

const persistConfig = {
  key: "root",
  storage,
  whiteList: [
    "metalist",
    "customers",
    "detaillist",
    "ticketList",
    'ticketassignList',
    "allcustomers",
    "taskList",
    "logList",
    "customerTickets",
    "userdepartment",
    "departmentlisting",
    "designationlisting",
    "activityList", 
    "taskcategory",
    "taskstatus",
    "profiledetails",
    "logtypelist",
    "ticketreport",
    "userdetail",
    "selectedIds",
    "logdetail"
  ],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    metalist: mataReducer,
    customers:customerReducer,
    detaillist:detailReducer,
    ticketList:ticketReducer,
    ticketassignList:ticketAssignReducer,
    allcustomers:allcustomerReducer,
    taskList:tasklistReducer,
    logList:loglistReducer,
    customerTickets:customerTicketsReducer,
    userdepartment: userReducer,
    ticketbycustomer: ticketbycustomerReducer,
    departmentlisting: deparmentReducer,
    designationlisting : designationReducer,
    activityList: activityReducer,
    taskcategory : taskcategoryReducer,
    taskstatus: taskstatusReducer,
    profiledetails: profiledetailsReducer,
    logtypelist: logtypeReducer,
    ticketreport:reportReducer,
    userdetail: userdetailReducer,
    selectedIds: selectedIdsReducer,
    logdetail: logdetailReducer
  })
);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store,persistor};