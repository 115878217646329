import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const {REACT_APP_SERVER_URL}=process.env;
export const fetchCustomerDatas  = createAsyncThunk(
  "filters/fetchCustomerDatas ",
  async (params) => {
    const { data } = await axios.get(
      `${REACT_APP_SERVER_URL}/api/customer/${parseInt(params.id)}`,
      {
        headers: {"Authorization" : `Bearer ${params?.token}`},
      }
    );
    return data.data;
  }
);

export const metaCustomerSlice= createSlice({
  name: "filtercustomer",
  initialState: {
    customer_list: [],
    error: null,
  },
  
  reducers: {
    
    setCustomerList: (state, action) => {
      state.customer_list = action.payload;
  },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerDatas .pending, (state) => {
      })
      .addCase(fetchCustomerDatas .fulfilled, (state, action) => {
        state.customer_list = action.payload;
          
      })
      .addCase(fetchCustomerDatas .rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase("persist/REHYDRATE", (state, action) => {
        // clear filter state here
        state.customer_list = [];
        state.error = null;
      });
  },
});

export const {
  setCustomerList
} = metaCustomerSlice.actions;
export default metaCustomerSlice.reducer;