import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const {REACT_APP_SERVER_URL}=process.env;
export const fetchLogTypeListDatas  = createAsyncThunk(
  "filters/fetchLogTypeListDatas ",
  async (params) => {
    const { data } = await axios.get(
      `${REACT_APP_SERVER_URL}/api/logTypeList`,
      {
        headers: {"Authorization" : `Bearer ${params?.token}`},
      }
    );
  
    return data.data;
  }
);

export const LogTypeListSlice= createSlice({
  name: "filtercustomer",
  initialState: {
    logtypelist: [],
    error: null,
    setSelectedIds:[]
  },
  
  reducers: {
    
    setSelectedIds: (state, action) => {
      state.selectedIds = action.payload;
  },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogTypeListDatas .pending, (state) => {
      })
      .addCase(fetchLogTypeListDatas .fulfilled, (state, action) => {
        state.logtypelist = action.payload;
          
      })
      .addCase(fetchLogTypeListDatas .rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase("persist/REHYDRATE", (state, action) => {
        // clear filter state here
        state.logtypelist = [];
        state.error = null;
      });
  },
});

export const {
  setSelectedIds
} = LogTypeListSlice.actions;
export default LogTypeListSlice.reducer;