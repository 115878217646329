import React, { useState, useEffect } from 'react'
import cale_vector from '../../assets/images/cale-vector.svg'
import axios from 'axios';
import tick from '../../assets/images/tick-icon.svg'
import greytick from '../../assets/images/tick-circle.svg'
import editvector from '../../assets/images/edit-vector.svg';
import deleteicon from '../../assets/images/delete.svg';
import reschedule from '../../assets/images/reshedule.svg'
import { fetchTaskListDatas } from "../../redux/TaskList";
import { useDispatch, useSelector } from "react-redux";
import AddTask from '../Task/AddTask';
import Rescheduletask from '../Task/Rescheduletask';
import UpdateTask from '../Task/UpdateTask';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { fetchTaskCategoryDatas } from '../../redux/TaskCategory';
import { fetchTaskStatusDatas } from '../../redux/TaskStatus';

const { REACT_APP_AUTH_SERVER } = process.env;
const { REACT_APP_SERVER_URL } = process.env;
function TaskTab({ id }) {
    const dispatch = useDispatch();
    const [showtask, setShowtask] = useState(false)
    const [updateshowtask, setupdateShowtask] = useState(false)
    const token = localStorage.getItem("token")
    const { task_list } = useSelector((state) => state.taskList);
    const [taskid, settaskid] = useState();

    
    const handleupdate = (id) => {
        setupdateShowtask(true)
        settaskid(id)
    }
    const deletetask = () => {

        axios({
            url: `${REACT_APP_SERVER_URL}/api/tasks/${taskid}`,
            method: 'DELETE',
            headers: { "Authorization": `Bearer ${token}` },
        }).then((res) => {
            toast.success(res.data.message)
            Closemodal()
            dispatch(fetchTaskListDatas({ token: token }))
        }).catch((er) => {

        })

    }
    const Closemodal = () => {
        const addCustomerModal = document.getElementById('deletetask');
        addCustomerModal.style.display = 'none';
    }
    return (
        <>
            <div className="activity-col">
                <div className="acti-head text-right pb-3">
                    <div className="export-col add-col addlog">
                        <button data-toggle="modal" data-target="#taskModal" onClick={(e) => setShowtask(true)}>
                            Add task&nbsp;
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                <path
                                    fill="currentColor"
                                    d="M11 17h2v-4h4v-2h-4V7h-2v4H7v2h4Zm1 5q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4Q8.65 4 6.325 6.325Q4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"
                                ></path>
                            </svg>
                        </button>
                    </div>
                </div>
                <ul className="activity-listing c-listing">
                    <div className="pb-3">
                        <h6>Recent</h6>
                    </div>
                    {task_list.map((obj) => {
                        return (

                            <li className="single-list d-flex align-items-baseline" >
                                <div className="timeline-row d-flex">
                                    {obj.created_by?
                                    <div className="timeline-icon">
                                        <p style={{textTransform:"uppercase"}}>{obj.created_by?.charAt(0)}</p>
                                    </div>:""
                                    }
                                    <div className="timeline-contents">
                                        <div className="timeline-header">
                                            <div className="t-header-contents d-flex justify-content-between">
                                                <div className="header-contents">
                                                    <p style={{textTransform:"capitalize"}}>{obj.title}</p>
                                                    {/* <p><span className="name">Getlead demo</span> created a <span className="task">Call task</span> for <span className="task-for">Amjith</span></p> */}
                                                    <p className="date">{obj.created_at}</p>
                                                </div>
                                                <div className="header-icons d-flex align-items-center">
                                                    <div className="">
                                                        <div data-toggle="modal" data-target="#updatetaskModal" onClick={(e) => handleupdate(obj.id)}> <img src={editvector} alt="Getlead" /> </div>
                                                    </div>
                                                    <div className="">
                                                        <a data-toggle="modal" data-target="#deletetask" onClick={() => settaskid(obj.id)}> <img src={deleteicon} alt="Getlead" /> </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="timeline-para">
                                                <p className={obj.status == "close" ? "task-done" : "task-not-done"}>
                                                    <span className="pr-1"><img src={obj.status == "close" ? tick : greytick} alt="Getlead" /> </span>{obj.description}
                                                </p>
                                            </div>
                                            <div className="timeline-status d-flex align-items-center justify-content-between">
                                                <div className="r-sch-div d-flex align-items-center">
                                                    <div className="sch-date d-flex align-items-center">
                                                        <img src={cale_vector} alt="Getlead" />
                                                        <p>&nbsp;Scheduled Date: <span>{obj.date_time}</span></p>
                                                    </div>
                                                    <div className="sch-completed"
                                                    >
                                                        <p>Status: <span style={{ color: obj.status == "open" ? "#ff9e00" : obj.status == "close" ? "#34bfa3" : "red" }}>{obj.status}</span></p>
                                                    </div>
                                                </div>
                                                {(obj.status != "close") ?
                                                    <div className="reshedule">
                                                        <a href="#" onClick={() => settaskid(obj.id)} data-toggle="modal" data-target="#rescheduleModal">
                                                            <img src={reschedule} alt="Getlead" />&nbsp; Reschedule
                                                        </a>
                                                    </div> : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade" id="deletetask" role="dialog" aria-hidden="true" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>

                                    <div className="modal-dialog ticket-modal customer-modal modal-dialog-centered" role="document" style={{ width: "450px" }}>
                                        <div className="modal-content">
                                            <div className="modal-body" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>

                                                <h3 className="text-center"> Confirm Delete ?</h3>
                                                <div className="row">


                                                    <div className="col-sm-12">
                                                        <div className="form-group text-center ">
                                                            <button className='deteletno mr-4' onClick={Closemodal}>no</button>
                                                            <button className='btn deteletyes w-20' onClick={deletetask}>yes</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className="modal fade add-activity-modal" id="taskModal" role="dialog" aria-hidden="true" data-backdrop="false" data-keyboard="true">
                {showtask ? <AddTask id={id} /> : ''}
            </div>
            <div className="modal fade add-activity-modal" id="updatetaskModal" role="dialog" aria-hidden="true" data-backdrop="false" data-keyboard="true">
                {updateshowtask ? <UpdateTask ticketid={id} taskid={taskid} /> : ''}
            </div>
            <div class="modal fade add-activity-modal" id="rescheduleModal" role="dialog" aria-hidden="true" data-backdrop="false" data-keyboard="true">
                <Rescheduletask id={taskid} />
            </div>
        </>
    )
}

export default TaskTab