import React, { useContext, useEffect, useState } from 'react' 
import { NewContext } from '../../Pages/Tickets/TicketListing'
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Oval } from "react-loader-spinner";
import $ from 'jquery';
import { fetchTicketbyCustomer } from '../../redux/TicketbyCustList';
import { useDispatch } from 'react-redux';
import { fetchCustomerDatas } from '../../redux/Customerlist';
import { fetchCustometTicketDatas } from '../../redux/CustomerTicketListSlice';

const { REACT_APP_AUTH_SERVER}= process.env;
const {REACT_APP_SERVER_URL}=process.env;

const Editcustomer = ({onClick,cusId}) => {
    const cusid = useParams();
    const dispatch = useDispatch()
    const token = localStorage.getItem("token")
    const [loading, setloading] = useState(false);
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        control,
        setValue,
        setFocus,
        reset,
        watch,
        setError,
        clearErrors,
        values,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    useEffect(() => {
      getCustomer()
    }, [cusId])
    // console.log();
    const getCustomer=async()=>{
        await axios({
            url:`${REACT_APP_SERVER_URL}/api/customer/${parseInt(cusId)}`,
            method:"GET",
            headers: {"Authorization" : `Bearer ${token}`}
        }).then((response)=>{
          
            // setcustomerdetail(response.data.data.customer)
            setValue("cus",{
                name:response.data.data.customer.name,
                email:response.data.data.customer.email,
                contact:response.data.data.customer.contact
            })
        }).catch((err) => {
         })
    }
    const handleupdatecustomer=async()=>{
        await axios({
          url:`${REACT_APP_SERVER_URL}/api/customer/${cusId}`,
          method:'PUT',
          headers: {"Authorization" : `Bearer ${token}`},
          data:{
              name:watch("cus.name"),
              email:watch("cus.email")?watch("cus.email"):null,
              contact:watch("cus,contact"),
              country_code:"+91"
          }
        }).then((res)=>{
            if(res.data.success){

                toast.success(res.data.message)
                dispatch(fetchTicketbyCustomer({token:token,id:cusId}))
                dispatch(fetchCustometTicketDatas({ token: token, current_page: 1 }))
                const addCustomerModal = document.getElementById('editcustomer');
                addCustomerModal.style.display = 'none'; 
                document.body.classList.remove('modal-open'); 

                //alert("test");
                // addCustomerModal.modal('hide');
            }
            else{
                {res.data.data.contact?toast.error(res.data.data.contact[0]):<></>}
                {res.data.data.email?toast.error(res.data.data.email[0]):<></>}
                
                // toast.error(res.data.data.email[0])
            }
        //   window.location.reload()
          
          
        }).catch((err) => {
          })
      }
      function preventOtherCharactersFromNumberInputcount(evt) {
        if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57 || evt.target.value.length == 10) {
            evt.preventDefault();
        }
    }
  return (
    <>
                                        <div className="modal-dialog modal-dialog-lg ticket-modal customer-modal modal-dialog modal-dialog-centered" role="document">
                                            <div className="modal-content">
                                                <div className="modal-body">
                                                    <form className="add-ticket-form" onSubmit={handleSubmit(handleupdatecustomer)}>
                                                        <h3 className="text-center">Update Customer</h3>
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <label for="">Customer name <span>*</span></label>
                                                                    <input type="text" className="form-control" 
                                                                    {...register("cus.name", {
                                                                        required: "Name is required",
                                                                    })}
                                                                    
                                                                    id="" aria-describedby="" placeholder="Enter customer name" />
                                                                    {errors?.cus?.name && <span className="error"> {errors.cus?.name.message}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <label for="">Contact number <span>*</span></label>
                                                                    <input 
                                                                    className="form-control" 
                                                                    {...register("cus.contact", {
                                                                        required: "Contact number is required",
                                                                    })}
                                                                    onKeyPress={preventOtherCharactersFromNumberInputcount}
                                                                    type="text"
                                                                    maxLength={10}
                                                                    id="" aria-describedby="" placeholder="" />
                                                                    {errors?.cus?.contact && <span className="error"> {errors.cus?.contact.message}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <label for="">Email ID</label>
                                                                    <input type="email" 
                                                                    {...register("cus.email")}
                                                                    className="form-control" id="" aria-describedby="" placeholder="" />
                                                                    {/* {errors?.cus?.email && <span className="error"> {errors.cus?.email.message}</span>} */}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <div className="form-group text-center mt-4">
                                                                    <button type="submit" className="add-ticket">
{/* <Oval
heigth="20"
width="20"
color='#fefefe'
arialLabel='loading'
/> */}
Update</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </>
  )
}

export default Editcustomer