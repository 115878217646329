import React from 'react'
import calender from '../../assets/images/calender.svg'
import axios from 'axios'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { fetchTaskListDatas } from '../../redux/TaskList';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

const { REACT_APP_SERVER_URL } = process.env;
const Rescheduletask = ({id}) => {

    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        control,
        setValue,
        setFocus,
        reset,
        watch,
        setError,
        clearErrors,
        values,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const token = localStorage.getItem("token")
    const [minDate, setMinDate] = useState('');

  // Get today's date in the format yyyy-mm-dd
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero if needed
  const day = today.getDate().toString().padStart(2, '0'); // Add leading zero if needed
  const todayFormatted = `${year}-${month}-${day}`;
    const dispatch = useDispatch()
    const handleReschedule =()=>{
       
        // const formatted = getValues().time.toISOString().slice(0, 19).replace('T', ' ');
        axios({
            url:`${REACT_APP_SERVER_URL}/api/re-schedule-task`,
            method:'POST',
            headers: { "Authorization": `Bearer ${token}` },
            data:{
                task_id:id,
                date:getValues().date,
                time:getValues().time,
                description:getValues().reason
            }
        }).then((res)=>{
            if(res.data.success){

                toast.success(res.data.message)
                dispatch(fetchTaskListDatas({token:token}))
                Closemodal()
            }
            else(
                toast.warn(res.data.message)
            )
        }).catch((err)=>{
            console.log(err);
        })
    }
    const Closemodal =()=>{
        const addCustomerModal = document.getElementById('bt1');
        addCustomerModal.click();
        clearErrors('date');
        clearErrors('time');
        clearErrors('reason');
       
                // addCustomerModal.style.display = 'none';
    }
    const currentDate = new Date().toISOString().slice(0, 10);
  return (
    <div class="modal-dialog modal-dialog-lg ticket-modal customer-modal log-modal" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" onClick={Closemodal}>
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body pt-1">
            <form class="add-ticket-form" onSubmit={handleSubmit(handleReschedule)}>
                <h3>Reschedule Task</h3>
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">Reschedule to</label>
                            <div class="datepicker date input-group">
                                <input type="date"
                                 {...register("date", {
                                    required: "Date is required"
                                })}
                                 placeholder="Choose Date" class="form-control" id="fecha1" 
                                 min={todayFormatted}
                                 />
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        {/* <img src={calender} alt="GetLead" /> */}
                                    </span>
                                </div>
                            </div>
                                  {errors?.date && <span className="error"> {errors.date.message}</span>}
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">Time</label>
                            <input type="time"
                            {...register("time", {
                                required: "Time is required"
                            })}
                            min={currentDate}
                             class="form-control" id="" aria-describedby="" />
                              {errors?.time && <span className="error"> {errors.time.message}</span>}
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="">Reason for reschedule</label>
                            <textarea class="form-control" 
                            {...register("reason", {
                                required: "Reason for reschedule is required"
                            })}></textarea>
                             {errors?.reason && <span className="error"> {errors.reason.message}</span>}
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="form-btn">
                            <button class="add-ticket w-100" type='submit'>Reschedule</button>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="form-btn">
                            <button style={{width: "fit-content" , padding: "12px 50px;" }}
                            className="cancel-btn w-100" type='reset'onClick={Closemodal} id="bt1" data-toggle="modal" data-target="#rescheduleModal">Cancel</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
  )
}

export default Rescheduletask