import React, { useEffect, useState } from 'react'
import { ErrorMessage } from '@hookform/error-message';
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select";
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { fetchTicketdetailDatas } from "../../redux/Ticketdetailist";
import { fetchTicketList } from '../../redux/TicketListSlice';
import { fetchTicketAssignList } from '../../redux/TikectlistAssign';
import { fetchTicketbyCustomer } from '../../redux/TicketbyCustList';

const { REACT_APP_SERVER_URL } = process.env;
const ChangeStaff = ({ ticket,closeChangeStaff, whichpage,customer_id }) => { 
    const dispatch = useDispatch();
    const { meta_list, prioArray, staArray, typeArray, asignArray, labArray, productArray } = useSelector((state) => state.metalist);
    const { detail_list } = useSelector((state) => state.detaillist);
    const [agent, setagent] = useState(detail_list)
    const [id, setId] = useState()
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        control,
        setValue,
        setFocus,
        reset,
        watch,
        setError,
        clearErrors,
        values,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    useEffect(() => {
        if (ticket) {
        //     const agent =ticket?.agent?Object.entries(ticket?.agent)?.map(([key, value]) => ({
        //         value: parseInt(value.user_id),
        //         label: value.name
        //     })):[]
        //    setassignedto(agent)
           setId(ticket?.id)
        }
    }, [])
    const token = localStorage.getItem("token")
    const [assignedto, setassignedto] = useState();
    const [assign, setassign] = useState([])
    const handleAssignedto = (value) => {
        setassignedto( value)
        setagent(value)
    }
    const update=()=>{
        
        axios({
            url: `${REACT_APP_SERVER_URL}/api/singleAgentChange`,
            method: "POST",
            headers: { "Authorization": `Bearer ${token}` },
            data: {
                ticket_id:parseInt(id),
                type:1,
                assigned_to:assignedto.value
            }
        }).then((res) => {
            if (res.data.success == true) {
                toast.success(res.data.message)   
               closeChangeStaff()  
               
               dispatch(fetchTicketbyCustomer({ token: token, id: customer_id }))
               document.getElementById("cancelbtn").click()   

               {whichpage?
                dispatch(fetchTicketList({
                    search: '',
                    filter_by: "",
                    status: [],
                    end_date: "",
                    priority: "",
                    agent: [],
                    start_date: "",
                    token: token,
                    current_page: 1,
                })):
                dispatch(fetchTicketAssignList({
                    search:"",
                    filter_by:"",
                    status:[],
                    end_date:"",
                    priority:"",
                    agent:[],
                    start_date:"",
                    token:token,
                    current_page:1,
                  }))
            }    
            }
            else {
                toast.error(res.data.message)
            }
        }).catch((err) => {
            if (err.request) { console.log(err.request) } if (err.response) {
                console.log(err.response)

            }
        })
    }
    return (
        <>
            <div className="modal-dialog modal-dialog-lg ticket-modal customer-modal modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <form className="add-ticket-form" >
                            <h3 className="text-center">Update Agent {ticket.issue}</h3>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor=""> Assigned To <span>*</span></label>

                                        <Select
                                        name='assignedto'
                                        options={asignArray}
                                       
                                        value={assignedto}
                                        defaultValue={agent}                                        
                                        onChange={(value)=>handleAssignedto(value)}
                                        />

                                    </div>

                                </div>


                                <div className="col-sm-12">
                                    <div className="form-group text-center mt-4">
                                        <button type="button" onClick={update} className="add-ticket">Update </button>
                                    </div>
                                </div>
                                <div id='cancelbtn' data-dismiss="modal"></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
         
        </>
    )
}

export default ChangeStaff