
import React, { useEffect, useState } from 'react'
import { ErrorMessage } from '@hookform/error-message';
import { ToastContainer, toast } from 'react-toastify';
import calender from '../../assets/images/calender.svg'
import Datetime from 'react-datetime'
import "react-datetime/css/react-datetime.css";
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { fetchLogListDatas } from '../../redux/LogList';
import { useDispatch, useSelector } from 'react-redux';
const { REACT_APP_SERVER_URL } = process.env;

const UpdateLog = ({ticketid, logId}) => {
    
    const token = localStorage.getItem("token")
    
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        control,
        setValue,
        setFocus,
        reset,
        watch,
        setError,
        clearErrors,
        values,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const {  logtypelist} = useSelector((state) => state.logtypelist);
    const {  logdetail_list} = useSelector((state) => state.logdetail);
    useEffect(() => {
        // getlogtype()
        // getLogDetail()
        setValue("log",{
            lognote:logdetail_list?.type_id,
            outcome:logdetail_list?.outcome_id,
            dueon:logdetail_list?.time,
            description:logdetail_list?.description,
        })
        setlogid(logdetail_list?.id)
       getoutcome(logdetail_list?.type_id)
        // setassignedto(res.data.data.task.agent?.map(user => {
        //     return {
        //         value: parseInt(user.user_id),
        //         label: user.name
        //     };
        // }))
        const originalDateString = logdetail_list?.time;
        const originalDate = new Date(originalDateString);

        const formattedDate = originalDate.toString();
        // settaskstartDate(formattedDate)
        // console.log(formattedDate);
        setlogStartDate(logdetail_list?.time)
        setlogformatted(formattedDate)
    }, [logdetail_list?.data])
    const dispatch = useDispatch();
    const [logstartDate, setlogStartDate] = useState(new Date());

    const [outcomelist, setoutcomelist] = useState([])
    const [logid, setlogid] = useState()
    const [logformatted, setlogformatted] = useState(new Date())
    const [changed, setchanged] = useState(false);
  
   
    const handlelogtype = async (event) => { 
        getoutcome(event.target.value)
    }
    const getoutcome = async (id) => {
        await axios({
            url: `${REACT_APP_SERVER_URL}/api/outcomes?type_id=${id}`,
            method: "GET",
            headers: { "Authorization": `Bearer ${token}` },
        }).then((res) => {
            // console.log(res.data.data,"out");
            setoutcomelist(res.data.data)
        }).catch((err) => {
        })
    }
    const handledate =(date)=>{
        const parsedDate = new Date(Date);
        
        const year = parsedDate.getFullYear();
        const month = ('0' + (parsedDate.getMonth() + 1)).slice(-2);
        const day = ('0' + parsedDate.getDate()).slice(-2);
        const hours = ('0' + parsedDate.getHours()).slice(-2);
        const minutes = ('0' + parsedDate.getMinutes()).slice(-2);
        
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
        setchanged(true)
        setlogformatted(formattedDate)
    }
    const UpdateLog = async () => {
        if(changed){
            
           setlogformatted(logstartDate)
        }
        else{

            // setlogStartDate(logstartDate.toISOString().slice(0, 19).replace('T', ' '));
            // console.log(logstartDate);
        }
      
        await axios({
            url: `${REACT_APP_SERVER_URL}/api/logs/${logid}`,
            method: "PUT",
            headers: { "Authorization": `Bearer ${token}` },
            data: {
                ticket_id: ticketid, 
                outcome_id: parseInt(getValues().log.outcome),
                type_id: parseInt(getValues().log.lognote),
                date_time: logstartDate,
                description: getValues().log.description,
            }

        }).then((res) => {
            // console.log(res.data);
            if (res.data.success == true) {
                toast.success(res.data.message)
                Closemodal()
                dispatch(fetchLogListDatas({token:token,ticketid:ticketid}))
            }
            else {
                toast.error(res.data.message)
            }
        }).catch((err) => {
        })
    }
  
    const Closemodal =()=>{
        const addCustomerModal = document.getElementById('updatelogModal');
                addCustomerModal.style.display = 'none';
    }
  return (
    <div class="modal fade add-activity-modal" id="updatelogModal" role="dialog" aria-hidden="true" data-backdrop="false" data-keyboard="true">
        <div className="modal-dialog modal-dialog-lg ticket-modal 
                                        customer-modal log-modal" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={Closemodal}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body pt-1">
                        <form className="add-ticket-form" onSubmit={handleSubmit(UpdateLog)}>
                            <h3>Update Log note</h3>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label for="">Log a </label>
                                        <select className="form-control"
                                            {...register("log.lognote", {
                                                required: "Log type is required"
                                            })}
                                            onChange={(e) => handlelogtype(e)}
                                        >
                                            <option value="">select</option>
                                            {logtypelist.map((log) => {
                                                return (
                                                    <option value={log.id}>{log.type}</option>
                                                )
                                            })}

                                        </select>
                                        {errors?.logtype && <span className="error"> {errors.logtype.message}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label for="">Outcome</label>
                                        <select className="form-control"
                                            {...register("log.outcome")}>
                                            <option value="">select</option>
                                            {outcomelist?.map((out) => {
                                                return (

                                                    <option value={out.id}>{out.outcome}</option>
                                                )
                                            })}
                                        </select>
                                        {/* {errors?.logtype && <span className="error"> {errors.logtype.message}</span>} */}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label for="">Due on</label>
                                        <div className="datepicker date input-group">
                                            {/* <input type="text" placeholder="Choose Date" className="form-control" id="fecha1" /> */}
                                            <Datetime

                                                name='date'
                                                
                                                value={logformatted} onChange={(date) => handledate(date)} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <img src={calender} alt="GetLead" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-6 col-md-12 col-sm-12">
                                                                <div className="form-group">
                                                                    <label for="">Time</label>
                                                                    <input type="email" className="form-control" id="" aria-describedby="" />
                                                                </div>
                                                            </div> */}
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label for="">Description</label>
                                        <textarea className="form-control"
                                            {...register("log.description", {
                                                required: "Description is required"
                                            })}></textarea>
                                        {/* {errors?.log.description && <span className="error"> {errors.log.description.message}</span>} */}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-btn">
                                        <button className="add-ticket w-100" type='submit'>Add</button>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-btn">
                                        <button
                                        type='reset'
                                            style={{ width: "fit-content", padding: "12px 50px" }}
                                            className="cancel-btn w-100" onClick={Closemodal}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            
        </div>

  )
}

export default UpdateLog