import { createSlice } from "@reduxjs/toolkit";

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const {REACT_APP_SERVER_URL}=process.env;
export const fetchTicketbyCustomer = createAsyncThunk(
  "filters/fetchTicketbyCustomer",
  async (searchData, thunkAPI) => {
    try {
      const response = await axios.get(
        `${REACT_APP_SERVER_URL}/api/customer/${searchData?.id}?search=${searchData?.search?searchData?.search:""}`,
        // {
        //     search:searchData.search?searchData.search:'',
        //     filter_by:searchData.filter_by?searchData.filter_by:'',          
        //     page:searchData.current_page?searchData.current_page:1,
        //     per_page:6
        // },
        {
          headers: {"Authorization" : `Bearer ${searchData?.token}`},
        }
      );
   
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const ticketListingSlice = createSlice({
  name: "ticketSearch",
  initialState: {
    search:"",
    // filter_by:"",
    // status:[],
    // end_date:"",
    // priority:"",
    // agent:[],
    // start_date:"",
    loading: true,
    error: "",
  },
  reducers: {
    setSearch: (state, action) => {
      state.customerdata = action.payload.data.customer;
    },
    setticket: (state, action) => {
      state.custTicketList = action.payload.data.customer.ticket;
    },
    // setFilter_by: (state, action) => {
    //   state.filter_by = action.payload;
    // },
    // setStatus: (state, action) => {
    //     state.status = action.payload;
    // },
    // setEnd_date: (state, action) => {
    //     state.end_date = action.payload;
    // },
    // setPriority: (state, action) => {
    //     state.priority = action.payload;
    // },
    // setAgent: (state, action) => {
    //     state.agent = action.payload;
    // },
    // setStart_date: (state, action) => {
    //     state.start_date = action.payload;
    // },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTicketbyCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTicketbyCustomer.fulfilled, (state, action) => {
        state.customerdata = action.payload.data?.customer;
        state.custTicketList = action.payload.data?.customer.ticket_details;
        state.loading = false;
      })
      .addCase(fetchTicketbyCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { 
    setSearch,
    // setFilter_by,
    // setStatus,
    // setEnd_date,
    // setPriority,
    // setAgent,
    // setStart_date
 } = ticketListingSlice.actions;

export default ticketListingSlice.reducer;
