import React, { useEffect, useState } from 'react';
import help from '../../assets/images/help.svg';
import logo from '../../assets/images/login-logo.svg';
import layout from '../../assets/images/layout-img.svg';
import '../../assets/css/style.css';
import '../../assets/css/resp.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Components/Loader/Loader';
const { REACT_APP_AUTH_SERVER}= process.env;
const {REACT_APP_SERVER_URL}=process.env;
function Login() {
    useEffect(() => {

    }, [])
    const [loading, setloading] = useState(false);
    const {
       register,
       formState: { errors, isValid, isDirty },
       handleSubmit,
       getValues,
       setValues,
       setFocus,
       values,
       reset,
       trigger,
   } = useForm({
       mode: "onChange",
       reValidateMode: "onChange",
   });
    const navigate=useNavigate();
    const handleSubmitForm = async (e) => {
        // e.preventDefault();
    
        if (isValid) {
          setloading(true)
          await axios({
            url: REACT_APP_SERVER_URL + "/api/login",
            method: 'POST',
            data: {
              email: getValues().email,
              password: getValues().password
            },
          }
          ).then((response) => {
            
            if (response.data.success==true) {
            //   setLoading(false)
              localStorage.setItem('token', response.data.data.token);
              localStorage.setItem('uname', response.data.data.user.name);
              localStorage.setItem('userid', response.data.data.user.id);
              
              toast.success("Logged in")
              document.body.style.overflow = "";
              setloading(false)
              navigate("/ticket-listing");
              // toast.success(response.data.message)
             
            }
            else {
            //   setLoading(false)
            toast.error(response.data.data.error)
            setloading(false)
            console.log(response.data);
            }
            // window.location.reload();
          })
          .catch((err) => {
            setloading(false)
            if(err.request){ console.log(err.request) } if(err.response){ 
                console.log(err.response)
          
          }})
        }
        // setLoading(false)
      }
  return (
    <div className="login-page">
        <Loader loading={loading}/>
    <div className="container-fluid">
        <div className="row ">
            <div className="col-lg-5 col-md-5 col-sm-12 p-0">
                <div className="login-slider">
                    <div className="login-logo">
                        <img src={logo} alt="Getlead"/>
                    </div>
                    <div className="layout-img">
                        <img src={layout} alt="Getlead"/>
                    </div>
                </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12 p-0">
                <div className="login-form d-flex align-items-center justify-content-center">
                    <div className="form-col">
                        <div className="help-text">
                            <a href="#"><span><img src={help} alt="Getlead"/> </span> Help center</a>
                        </div>
                        <form onSubmit={handleSubmit(handleSubmitForm)}>
                            <div className="form-header">
                                <h2>Login to your account!</h2>
                                <p>Log in to your account</p>
                            </div>
                            <div className="form-group">
                                <label >Email address / Mobile number</label>
                               
                          <input type="text" 
                                {...register("email", {
                                  required: true,
                                  pattern:
                                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                     
                              })}
                                className="form-control"  
                                placeholder="Enter email"/>
                           {errors.email?.type === "required" && (
                         <span className="error">Email is required</span>
                        )}
                        {errors.email?.type === "pattern" && (
                        <span className="error">Email is not valid</span>
                        )}
                         {errors.email && <span className="error">{errors.email.message}</span>}
                        {/* {errors.email?.type === "pattern" && (
                        <span className="error">Email is not valid</span>
                        )} */}
                              </div>
                              <div className="form-group">
                                <label >Password</label>
                                <input type="password" 
                                {...register("password", {
                                    required: " Password is required",
                                })}
                                className="form-control"  
                                placeholder="Enter your password"/>
                                 <p>{errors.password && <p className="error"> {errors.password.message}</p>}</p>
                                <Link className="forgot" to={'/forgotpassword'}>Forgot Password?</Link>
                              </div>
                              <button type="submit" className="btn login-btn"
                              style={{marginBottom:"60px"}} 
                              >Login</button>
                              <div className="form-group signup-grp" >
                                <a>Don't have an account yet?</a> <br/>
                                <button type="button" className="btn signup-btn" onClick={e=>navigate('/Signup')}>Create an account</button>    
                              </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <ToastContainer />
    </div>
</div>
  )
}

export default Login