import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const {REACT_APP_SERVER_URL}=process.env;
export const fetchTaskCategoryDatas  = createAsyncThunk(
  "filters/fetchTaskCategoryDatas ",
  async (params) => {
    const { data } = await axios.get(
      `${REACT_APP_SERVER_URL}/api/task-category`,
      {
        headers: {"Authorization" : `Bearer ${params?.token}`},
      }
    );
   
    return data.data;
  }
);

export const metaTaskcategorySlice= createSlice({
  name: "filtercustomer",
  initialState: {
    task_category: [],
    error: null,
  },
  
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskCategoryDatas .pending, (state) => {
      })
      .addCase(fetchTaskCategoryDatas .fulfilled, (state, action) => {
        state.task_category = action.payload.taskCategory;
          
      })
      .addCase(fetchTaskCategoryDatas .rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase("persist/REHYDRATE", (state, action) => {
        // clear filter state here
        state.task_category = [];
        state.error = null;
      });
  },
});

export const {
  
} = metaTaskcategorySlice.actions;
export default metaTaskcategorySlice.reducer;