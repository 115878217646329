import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const {REACT_APP_SERVER_URL}=process.env;
export const fetchLogListDatas  = createAsyncThunk(
  "filters/fetchLogListDatas ",
  async (params) => {

    const { data } = await axios.get(
      `${REACT_APP_SERVER_URL}/api/logs?ticket_id=${params?.ticketid}`,
      {
        headers: {"Authorization" : `Bearer ${params?.token}`},
      }
    );

    return data.data;
  }
);

export const metaLoglistSlice= createSlice({
  name: "filtercustomer",
  initialState: {
    log_list: [],
    error: null,
  },
  
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogListDatas .pending, (state) => {
      })
      .addCase(fetchLogListDatas .fulfilled, (state, action) => {
        state.log_list = action.payload.data;
        state.log_listpagination = action.payload.pagination;
          
      })
      .addCase(fetchLogListDatas .rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase("persist/REHYDRATE", (state, action) => {
        // clear filter state here
        state.log_list = [];
        state.error = null;
      });
  },
});

export const {
  
} = metaLoglistSlice.actions;
export default metaLoglistSlice.reducer;