import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input/input'
import { fetchAllCustomerMetaData,setCustomerDetails } from "../../redux/Customermetadata";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { fetchCustomerDatas } from '../../redux/Customerlist';
import { fetchCustometTicketDatas } from '../../redux/CustomerTicketListSlice';
import { useContext } from 'react';
import { custcontext } from '../Ticket/AddTicket';



const { REACT_APP_AUTH_SERVER } = process.env;
const { REACT_APP_SERVER_URL } = process.env;
const AddCustomer = () => {
    const token = localStorage.getItem("token")
    const dispatch = useDispatch();
    // const {value1} = useContext(custcontext) 
    // const [cusname, setcusname] = useState(value1)
    const navigate = useNavigate()
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        control,
        setValue,
        setFocus,
        reset,
        watch,
        setError,
        clearErrors,
        values,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const [mobile, setmobile] = useState()
    function preventOtherCharactersFromNumberInputlimits(evt) {
        if (
            (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
            evt.which > 57 ||
            evt.target.value.lenth == 20
        ) {
            evt.preventDefault();
        }
    }
    useEffect(()=>{
        dispatch(setCustomerDetails(''))
    },[])
    const addCustomer = async () => {

        await axios({
            url: `${REACT_APP_SERVER_URL}/api/customer`,
            method: "POST",
            headers: { "Authorization": `Bearer ${token}` },
            data: {
                name: getValues().cusname,
                email: getValues().cusemail?getValues().cusemail:null,
                contact: getValues().cusnumber,
                country_code: "+91"
            }
        }).then((res) => {
            // console.log(res.data.data['customer'],'opopop');
            if (res.data.success) {
                setValue()
                reset({
                    'cusname': '',
                    'cusemail': '',
                    'cusnumber': '',
                   
                });
                toast.success(res.data.message)
                dispatch(setCustomerDetails({
                    value:res?.data?.data['customer']?.id,
                    label:res?.data?.data['customer']?.name
                }))
                dispatch(fetchAllCustomerMetaData(token))
                dispatch(fetchCustometTicketDatas({ token: token, current_page: 1 }))
                // const addCustomerModal = document.getElementById('addCustomer');
                // setcusname()
                // addCustomerModal.style.display = 'none';
                closescreen()
                // alert("test");
                // document.getElementById('customerCancel').click()

            } else {
                {res.data.data.contact?toast.error(res.data.data.contact[0]):<></>}
                {res.data.data.email?toast.error(res.data.data.email[0]):<></>}
                // console.log(res.data.data.contact[0],res.data.data.email[0]);
            }
        }).catch((err) => {
        })
    }
    function cancelButtonClick(event) {
        event.stopPropagation();
        // Your other code for closing the modal here
    }
    const closescreen = () => {
        clearErrors('cusname');
        clearErrors('cusnumber');
        var tick = document.querySelector('#ticketModal');

        const addCustomer = document.getElementById('addCustomer');
        //addCustomerModal.Modal('hide');
                //addCustomerModal.style.display = 'none';
                //addCustomerModal.classList.remove('show')
        //         const parentDiv = addCustomerModal.closest('#ticketModal');
                // if(tick.classList.contains('show')) {
                //     //alert(tick);
                //     tick.classList.add("closeM1");
                //     addCustomer.classList.add("closedM")
                // }
                // else {
                //     const addCustomerModal = document.getElementById('closeButton8');
                //     addCustomerModal.click();
                // }
        const addCustomerModal = document.getElementById('closeButton8');
        addCustomerModal.click();


    }
    return (
        <>
            <div className="modal-dialog modal-dialog-lg ticket-modal customer-modal modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content add-department">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={closescreen} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ padding: "0px 40px 20px" }}>
                        <form className="add-ticket-form" onSubmit={handleSubmit(addCustomer)}>
                            <h3 className="text-center" style={{ padding: " 2px 40px 1px" }}>Add customer</h3>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group" style={{ marginTop: "20px", marginBottom: "5px" }}>
                                        <label for="">Customer name <span>*</span></label>
                                        <input type="text"
                                            {...register("cusname", {
                                                required: true,
                                            })}
                                            className="form-control" id="" aria-describedby="" placeholder="Enter customer name" />
                                        {errors.cusname?.type === "required" && (
                                            <span className="error">Name is required</span>)}
                                    </div>

                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group" style={{marginBottom: "5px" }}>
                                        <label for="">Contact number <span>*</span></label>
                                        <input
                                            country="IND"
                                            {...register("cusnumber", {
                                                required: true,
                                            })}
                                            onKeyPress={
                                                preventOtherCharactersFromNumberInputlimits
                                            }
                                            className="form-control"
                                            type="text"
                                            maxLength={10}
                                        />
                                        {errors.cusnumber?.type === "required" && (
                                            <span className="error">Contact Number is required</span>
                                        )}

                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group" style={{ marginBottom: "5px" }}>
                                        <label for="">Email ID</label>
                                        <input type="email"
                                            {...register("cusemail", {
                                                pattern:
                                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

                                            })}
                                            className="form-control" id="" aria-describedby="" placeholder="" />
                                        {errors.cusemail?.type === "pattern" && (
                                            <span className="error">Email is not valid</span>
                                        )}
                                    </div>

                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group text-center mt-4">
                                        <button type="submit" className="add-ticket" style={{ width: "85%" }}>Send invitation</button>
                                        <a className="close-bt-new" id="closeButton8"  data-toggle="modal" data-target="#addCustomer">Save</a>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AddCustomer