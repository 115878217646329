import React from 'react'
import cale_vector from '../../assets/images/cale-vector.svg'
import Addlog from '../Log/Addlog'
import axios from 'axios'
import { useState } from 'react';
import { useEffect } from 'react';
import { fetchLogListDatas } from '../../redux/LogList';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from "@mui/material/Pagination";

const { REACT_APP_SERVER_URL } = process.env;
function LogTab({id}) {
    const dispatch = useDispatch();    
    const token = localStorage.getItem("token")
    const [loglist, setloglist] = useState([])
    const [totalRecords, settotalRecords] = useState([])
    const [page, setPage] = useState(1)
    const { log_list, log_listpagination } = useSelector((state) => state.logList);
    
    
    const changePage = (value) => {
        setPage(value)
        
                // dispatch(fetchTicketList({
                //     search: '',
                //     filter_by: "",
                //     status: [],
                //     end_date: "",
                //     priority: "",
                //     agent: [],
                //     start_date: "",
                //     token: token,
                //     current_page: value,
                // })) 

              
    };
    return (
        <div className="activity-col">
            <div className="acti-head text-right pb-3">
                <div className="export-col add-col addlog">
                    <button data-toggle="modal" data-target="#logModal">
                         Add log note &nbsp;&nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                            <path
                                fill="currentColor"
                                d="M11 17h2v-4h4v-2h-4V7h-2v4H7v2h4Zm1 5q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4Q8.65 4 6.325 6.325Q4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
            <ul className="activity-listing c-listing after">
                <div className="pb-3">
                    <h6>Recent</h6>
                </div>
               
                {log_list?.map((log)=>{
                    return(

                <li className="single-list d-flex align-items-baseline">
                    <div className="timeline-row d-flex">
                        <div className="timeline-icon">
                            <p style={{textTransform:"uppercase"}}>{log.title?.charAt(0)}</p>
                        </div>
                        <div className="timeline-contents">
                            <div className="timeline-header">
                                <div className="t-header-contents d-flex justify-content-between">
                                    <div className="header-contents">
                                        {/* <p><span className="name">Getlead demo</span> left a <span className="task">note</span> for</p> */}
                                        <p><span className="name">{log.title}</span> </p>
                                        <p className="date">{log.created_at}</p>
                                    </div>
                                </div>
                                <div className="timeline-para">
                                    <p className="">
                                        {log.description}
                                          </p>
                                </div>
                                {log.type_id==2?
                                " ":
                                
                                <div className="timeline-status d-flex align-items-center">
                                    <div className="sch-date d-flex align-items-center">
                                        <img src={cale_vector} alt="Getlead" />
                                        <p>&nbsp; <span>{log.date_time}</span></p>
                                    </div>
                                    <div className="sch-completed sch-busy">
                                        <p>Outcome: <span style={{ color: "#ff8f71" }}>{log.outcome}</span></p>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </li>
                    )
                })}
                 {log_listpagination?.total>0?
            
            <Pagination
                count={log_listpagination?.total_pages}
                color="primary"
                variant="outlined"
                onChange={(e, value) => changePage(value)}
                page={log_listpagination?.current_page}

            />
        :""}
                 
            </ul>
            <Addlog id={id} />
        </div>
    )
}

export default LogTab