import axios from 'axios';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import AddDesgnation from './AddDesgnation';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery'
import { fetchUserdeparment } from '../../redux/UserDepartment';
import dot from '../../assets/images/3dot.svg'

const {REACT_APP_SERVER_URL}=process.env;
const Designation = ({List}) => {
    
 
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        control,
        setValue,
        setFocus,
        reset,
        watch,
        setError,
        clearErrors,
        values,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const [desg, setdesg] = useState()
    const token = localStorage.getItem("token")
    const dispatch = useDispatch()
    const {  userdpt_list ,userlist,departmentlist,designationlist} = useSelector((state) => state.userdepartment);
    const [loading, setloading] = useState(false);  
    const [updatdesignation, setupdatdesignation] = useState();  
    const [desid, setdesid] = useState();  
    const [openDropdown, setOpenDropdown] = useState(null);
    const [id, setid] = useState();  
   
    const getdesignation=(id)=>{
        axios({
            url:`${REACT_APP_SERVER_URL}/api/designation/${id}`,   
            method:'GET',
            headers: {"Authorization" : `Bearer ${token}`},
        }).then((res)=>{
        //     console.log(
        //         res.data
        //    ,"laaaaaaaaaaaaaa" );
           setupdatdesignation(res.data.data.designation.designation)
           setdesid(res.data.data.designation.id)
           setValue("des",res.data.data.designation.id)
        }).catch((er)=>{
            toast.error("error")
        })
    }
   
    const updateDesignation=()=>{
        axios({
            url:`${REACT_APP_SERVER_URL}/api/designation/${desid}`,
            data:{
                designation:updatdesignation
            },
            method:'PUT',
            headers: {"Authorization" : `Bearer ${token}`},
        }).then((res)=>{
            // console.log(
            //     res.data
            // );
            if (res.data.success){
                toast.success(res.data.message)
                Closemodal1()
                // const updateDesignationModal = document.getElementById('updateDesignation');
                // updateDesignationModal.style.display = 'none'; 
                // document.addEventListener('DOMContentLoaded', function() {
                //     // Find the element with the data-dismiss attribute and add a click event listener to it
                //     document.querySelector('[data-dismiss="modal"]').addEventListener('click', function() {
                //         // Close the modal programmatically using Bootstrap's method
                //         $('#updateDesignation').modal('hide');
                //         // var x =  document.getElementsByClassName('modal-backdrop');
                //         // alert(x);
                //         //document.getElementsByClassName('modal-backdrop').remove()
                //     });
                //     });
             List()
               
             
            }
        }).catch((er)=>{
            // toast.error("error")
            console.log(er);
        })
    }
    const Closemodal1=()=>{
        var addCustomerModal = document.getElementById('closeFun2');
        addCustomerModal.click()
    }
    const deletedesignation=()=>{
       
        axios({
            url:`${REACT_APP_SERVER_URL}/api/designation/${id}`,
            method:'DELETE',
            headers: {"Authorization" : `Bearer ${token}`},
           
        }).then((res)=>{
            // console.log(res.data,"success");
            if (res.data.success){
                toast.success(res.data.message)
             Closemodal()
             dispatch(fetchUserdeparment({token:token}))
                setloading(false)
             
            }
            else{
  
               toast.error(res.data.data.email[0])
               setloading(false)
            }
         }) .catch((err) => {
            
            if(err.request){
               
                console.log(err.request) } if(err.response){ 
                // console.log(err.response,'jjjjjjjjjjjj')
                // toast.error(err.response.data.data.email[0])
                setloading(false)
          }})
  
    }
    const toggleDropdown = (index) => {
        // alert("t");
       
        if (openDropdown === index) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(index);
        }
    };
    const Closemodal =()=>{
       // alert("t");
       // const addCustomerModal2 = document.getElementById('deletedesignation');
        var addCustomerModal = document.getElementById('closeFun1');
        addCustomerModal.click()
        
    }
  return (
    <div >
    <div className="users-sec">
        <div className="filter-report align-items-center">
            <div className="report-head">
                <h4>Designation</h4>
            </div>
            <div className="export-col add-col d-flex align-items-center">
                <div className="export-col add-col ml-5 addlog">
                    <button data-toggle="modal" data-target="#addDesignation">
                        Add Designation&nbsp;&nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                            <path
                                fill="currentColor"
                                d="M11 17h2v-4h4v-2h-4V7h-2v4H7v2h4Zm1 5q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4Q8.65 4 6.325 6.325Q4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div className="gl-table table-dept">
            <table>
                <thead>
                    <tr>
                        <th>Designation</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {designationlist?.map((des,index)=>{
                        return(

                    <tr>
                        <td>{des.designation}</td>

                        <td>
                        
                                            <div className="dropdown show dropdown-dots dropdown-show" style={{ background: "" }} onClick={(e) => {
                                                e.preventDefault();
                                                toggleDropdown(index);
                                            }} >
                                                <a className="btn dropdown-toggle" href="#" role="button" id={`dropdownMenuLink-${index}`}

                                                // data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                >
                                                 
                                                    {/* <i className="fa fa-ellipsis-v dots" aria-hidden="true"></i> */}
                                                    <img src={dot} alt=''></img>
                                                </a>
                                                {openDropdown === index && (
                                                    <div className="dropdown-menu" aria-labelledby={`dropdownMenuLink-${index}`}>
                                                       <a className="dropdown-item" href="#" onClick={() => getdesignation(des.id)}  data-target="#updateDesignation" data-toggle="modal">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M2 26h28v2H2zM25.4 9c.8-.8.8-2 0-2.8l-3.6-3.6c-.8-.8-2-.8-2.8 0l-15 15V24h6.4l15-15zm-5-5L24 7.6l-3 3L17.4 7l3-3zM6 22v-3.6l10-10l3.6 3.6l-10 10H6z"
                                                                ></path>
                                                            </svg>
                                                            &nbsp;Edit
                                                        </a>
                                                        <a href="#" data-toggle="modal" data-target="#deletedesignation" className="dropdown-item lead-delete" onClick={()=>setid(des.id)}> <i className="fa fa-trash-o" aria-hidden="true"></i>&nbsp;Delete </a>
                                                    </div>
                                                )}
                                            </div>
                            
                        </td>
                    </tr>
                        )
                    })}
                    
                </tbody>
            </table>
        </div>
    </div>
    {/* <!-- Add designation modal --> */}
    <div className="modal fade" id="addDesignation" role="dialog" aria-hidden="true">
           <AddDesgnation List={List} />
           <a className="close-bt-new" id="closeButton7"  data-toggle="modal" data-target="#addDesignation">Save</a>
        </div>
    {/* <!-- Update designation modal --> */}
    <div className="modal fade" id="updateDesignation" role="dialog" aria-hidden="true">
            <div className="modal-dialog ticket-modal customer-modal modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <form className="add-ticket-form" onSubmit={handleSubmit(updateDesignation)}>
                            <h3 className="text-center">Update designation</h3>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label for="">Designation name<span className="asterisk">*</span></label>
                                        <div className="multi-field-wrapper">
                                            <div className="multi-fields">
                                                <div className="multi-field mobile-group d-flex align-items-center mb-3">
                                                    <input type="text" id="mobile_code"
                                                    maxLength={50}
                                                    {...register("des", {
                                                        required: "Designation is required"
                                                    })}
                                                    className="form-control mobile_code" 
                                                    value={updatdesignation} onChange={(e)=>{setupdatdesignation(e.target.value)}}
                                                    name="stuff[]" placeholder="Enter designation" />
                                                    {/* <a type="button" className="add-dlt-btn add-field"><i className="fa fa-plus" aria-hidden="true"></i></a>
                                                    <a type="button" className="add-dlt-btn remove-field"><i className="fa fa-minus" aria-hidden="true"></i></a> */}
                                                </div>
                                                {errors.des?.type === "required" && (
                                                    <span className="error ">Designation is required</span>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group text-center mt-4">
                                        <button type="submit" className="add-ticket">Save</button>
                                        <a type="submit" className="add-ticket" id="closeFun2" style={{opacity:"0" ,width: "100%",    height: "3px"}} data-target="#updateDesignation" data-toggle="modal">close</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="deletedesignation" role="dialog" aria-hidden="true" style={{boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>

<div className="modal-dialog ticket-modal customer-modal modal-dialog-centered"  role="document" style={{width:"450px"}}>
    <div className="modal-content">
        <div className="modal-body" style={{boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
        
                <h3 className="text-center"> Are you sure to delete this Designation ?</h3>
                <div className="row">
                    
                
                    <div className="col-sm-12">
                        <div className="form-group text-center ">
                                <button className='deteletno mr-4' id="closeFun1" data-toggle="modal" data-target="#deletedesignation" >no</button>
                        <button className='btn deteletyes w-20' onClick={deletedesignation}>yes</button>
                        </div>
                    </div>
                </div>
            
        </div>
        </div>
        </div>   
</div>
</div>
  )
}

export default Designation