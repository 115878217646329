import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const {REACT_APP_SERVER_URL}=process.env;
export const fetchMetaDatas  = createAsyncThunk(
  "filters/fetchMetaDatas ",
  async (params) => {
    const { data } = await axios.get(
      `${REACT_APP_SERVER_URL}/api/metadata`,
      {
        headers: {"Authorization" : `Bearer ${params?.token}`},
      }
    );
    return data.data;
  }
);

export const metaFilterSlice= createSlice({
  name: "filtermeetup",
  initialState: {
    metaListData: [],
    prioArray:[],
    staArray:[],
    typeArray:[],
    asignArray:[],
    labArray:[],
    productArray:[],
    checkproduct:[],
    error: null,
  },
  
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetaDatas .pending, (state) => {
      })
      .addCase(fetchMetaDatas .fulfilled, (state, action) => {
        state.meta_list = action.payload;
        state.prioArray=Object.entries(action.payload.ticket_priorities)?.map(([key, value]) => ({
                value: parseInt(key),
                label: value
            }));
        state.staArray=Object.entries(action.payload.ticket_statuses)?.map(([key, value]) => ({
          value: parseInt(key),
          label: value
          }));
          state.typeArray = Object.entries(action.payload.ticket_types)?.map(([key, value]) => ({
            value: parseInt(key),
            label: value
        }));
        state.asignArray = action.payload.agent?Object.entries(action.payload.agent)?.map(([key, value]) => ({
            value: parseInt(key),
            label: value
        })):[];
    
        state.labArray = Object.entries(action.payload.ticket_labels)?.map(([key, value]) => ({
            value: parseInt(key),
            label: value
        }));
        state.productArray = action.payload.additional_field[0]?Object.entries(action.payload?.additional_field[0]?.value[0])?.map(([value,key]) => ({
            value: parseInt(key),
            label: value
        })):[];
        state.productId=(action.payload.additional_field[0]?.id)?.toString();
        state.checkproduct=(action.payload.additional_field[0])
          
      })
      .addCase(fetchMetaDatas .rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase("persist/REHYDRATE", (state, action) => {
        // clear filter state here
        state.meta_list = [];
        state.error = null;
      });
      
  },
});

export const {
  
} = metaFilterSlice.actions;
export default metaFilterSlice.reducer;