import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import Loader from '../../Components/Loader/Loader';
import editvector from '../../assets/images/edit-vector.svg';
import deleteicon from '../../assets/images/delete.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import addicon from '../../assets/images/add-icon-2.svg';
import axios from 'axios';
import { fetchCustometTicketDatas } from "../../redux/CustomerTicketListSlice";
import { ToastContainer, toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import AddcustomerTicket from '../../Components/Ticket/AddcustomerTicket';
import Editcustomer from '../../Components/Customer/Editcustomer';
import $ from 'jquery';
import { Pagination } from '@mui/material';


const { REACT_APP_AUTH_SERVER } = process.env;
const { REACT_APP_SERVER_URL } = process.env;
function TicketListCus({page}) {
    const dispatch = useDispatch();
    
    const navigate = useNavigate()
    const { loading, totalRecords, cus_ticket_list } = useSelector((state) => state.customerTickets);
    const token = localStorage.getItem("token")
    const [cusId, setcusId] = useState()
    const deleteCustomer = () => {
        

                    axios({
                        url: `${REACT_APP_SERVER_URL}/api/customer/${cusId}`,
                        method: "DELETE",
                        data:{
                            id:[cusId]
                        },
                        headers: { "Authorization": `Bearer ${token}` },
                    }).then((res) => {
                        toast.success(res.data.message);
                        dispatch(fetchCustometTicketDatas({ token: token, current_page: page }));
                        // Closemodal()
                        document.body.classList.remove('modal-open'); 
                    }).catch((err) => {
                    })

              
    }
    const closeTicket = () => {
        const addCustomerModal = document.getElementById('customerticketModal');
        addCustomerModal.style.display = 'none';
        document.addEventListener('DOMContentLoaded', function () {
            // Find the element with the data-dismiss attribute and add a click event listener to it
            document.querySelector('[data-dismiss="modal"]').addEventListener('click', function () {
                // Close the modal programmatically using Bootstrap's method
                $('#customerticketModal').modal('hide');
            });
        });
        dispatch(fetchCustometTicketDatas({ token: token, current_page: 1 }))
    }
    const customerticket=(id)=>{
        navigate('/customerticket/'+id)
       
    }
  
    const Closemodal =()=>{
        const addCustomerModal = document.getElementById('deletetagent');
                addCustomerModal.style.display = 'none';
    }
    
    return (
        <div>
            <Loader loading={loading} />
            {cus_ticket_list.map((cus,index) => {
                return (

                    <div className="listing-col listing-col-2" key={index}>
                        <div className="row">
                            <div className="col-lg-7 col-md-7 col-sm-12">
                                <div className="ticket-deatils d-flex align-items-start">
                                    <div className="t-check mr-3">
                                        <input type="checkbox" />
                                    </div>
                                    <div className="t-contents">
                                        <div className="contents-col d-flex align-items-center">
                                            <div className="t-name">
                                                <p>{cus.name}</p>
                                            </div>
                                            {cus.ticket>0?
                                            <div className="tickets-num ml-2"
                                            style={{cursor:"pointer"}}
                                             onClick={()=>customerticket(cus.id)}>
                                                {cus.ticket<10?"0"+cus.ticket:cus.ticket} Tickets
                                            </div>:" "
                                            }
                                        </div>
                                        <p className="ab-text">
                                            <a href="tel:9447755276">{cus.contact}</a>
                                            <a href="mailto:sharun@getlead.co.uk">{cus.email}</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-3 col-sm-12">
                                <div className="created-col">
                                    <div className="header-icons d-flex align-items-center">
                                        <div className="">
                                            <Link onClick={()=>setcusId(cus.id)}> <img src={editvector}
                                                data-toggle="modal" data-target="#editcustomer" alt="Getlead"  /> </Link>
                                        </div>
                                        <div className=""  data-toggle="modal" data-target="#deletetagent">
                                            <a
                                                style={{
                                                    cursor: "pointer"
                                                }}> <img src={deleteicon} alt="Getlead"
                                                    onClick={() => setcusId(cus.id)} /> </a>
                                        </div>
                                        <div className="">
                                            <Link onClick={()=>setcusId(cus.id)}> <img src={addicon} alt="Getlead"
                                                data-toggle="modal" data-target="#customerticketModal" /> </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
              
            
             <div className="modal fade" id="customerticketModal" role="dialog" aria-hidden="true">
                <AddcustomerTicket closeTicket={closeTicket} cusId={cusId}/>
            </div>
            <div className="modal fade" id="editcustomer" role="dialog" aria-hidden="true">
                <Editcustomer cusId={cusId}/>
            </div>
            <div className="modal fade" id="deletetagent" role="dialog" aria-hidden="true" style={{boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>

                {/* <div className="modal-dialog ticket-modal customer-modal modal-dialog-centered"  role="document" style={{width:"450px"}}> */}
                <div className="modal-dialog ticket-modal customer-modal modal-dialog-centered"  role="document">
                    <div className="modal-content">
                        <div className="modal-body" style={{boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
                        
                                <h3 className="text-center"> Confirm Delete ?</h3>
                                <div className="row">
                                    
                                
                                    <div className="col-sm-12">
                                        <div className="form-group text-center ">
                                                <button className='deteletno mr-4' data-toggle="modal" data-target="#deletetagent">no</button>
                                        <button className='btn deteletyes w-20' onClick={deleteCustomer}>yes</button>
                                        </div>
                                    </div>
                                </div>
                            
                        </div>
                </div>
             </div>   
        </div>
</div>
    )
}

export default TicketListCus