import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const {REACT_APP_SERVER_URL}=process.env;
export const fetchReportDatas  = createAsyncThunk(
  "filters/fetchReportDatas ",
  async (params) => {
    const { data } = await axios.post(
      `${REACT_APP_SERVER_URL}/api/ticket-status-report`,
      {
        status:params?.status?params?.status:[],
        agent:(params.agent=="all")?[]:params.agent,
        page:params?.page?params?.page:'',
        per_page:10,
        start_date:params?.start_date,
        end_date:params?.end_date
      },
      {
        headers: {"Authorization" : `Bearer ${params?.token}`},
      }
    );
   
    return data.data;
  }
);

export const ReportlistSlice= createSlice({
  name: "filtercustomer",
  initialState: {
    ticketreport: [],
    error: null,
    loading:true
  },
  
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportDatas .pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchReportDatas .fulfilled, (state, action) => {
        state.ticketreport = action.payload.data;
        state.pagination = action.payload.pagination;
        state.loading = false; 
      })
      .addCase(fetchReportDatas .rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase("persist/REHYDRATE", (state, action) => {
        // clear filter state here
        state.ticketreport = [];
        state.error = null;
        state.loading = false;
      });
  },
});

export const {
  
} = ReportlistSlice.actions;
export default ReportlistSlice.reducer;