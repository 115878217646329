import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const {REACT_APP_SERVER_URL}=process.env;
export const fetchUserDetails  = createAsyncThunk(
  "filters/fetchUserDetails ",
  async (params) => {
   
    const { data } = await axios.post(
      `${REACT_APP_SERVER_URL}/api/user-info`,

      { user_id:params.id?params.id:1,
       
      },
      {
        headers: {"Authorization" : `Bearer ${params?.token}`},
      },
    );
   
    return data.data;
  }
);

export const userDetailFilterSlice= createSlice({
  name: "filtermeetup",
  initialState: {
    userdetail: [],
    error: null,
  },
  
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetails .pending, (state) => {
      })
      .addCase(fetchUserDetails .fulfilled, (state, action) => {
        state.userdetail = action.payload.user;    
      })
      .addCase(fetchUserDetails .rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase("persist/REHYDRATE", (state, action) => {
        // clear filter state here
        state.userdetail = [];
        state.error = null;
      });
  },
});

export const {
  
} = userDetailFilterSlice.actions;
export default userDetailFilterSlice.reducer;