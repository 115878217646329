import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const {REACT_APP_SERVER_URL}=process.env;
export const fetchUserdeparment  = createAsyncThunk(
  "filters/fetchUserdeparment ",
  async (params) => {

    const { data } = await axios.post(
      `${REACT_APP_SERVER_URL}/api/user-department`,

      { page:params.page?params.page:1,
        search:params.search?params.search:"",
        per_page:10
        // department_per_page:params.page?params.page:1,
        // designation_per_page:2,
      },
      {
        headers: {"Authorization" : `Bearer ${params?.token}`},
      },
    );
   
    return data.data;
  }
);

export const usrdptFilterSlice= createSlice({
  name: "filtermeetup",
  initialState: {
    userData: [],
    error: null,
  },
  
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserdeparment .pending, (state) => {
      })
      .addCase(fetchUserdeparment .fulfilled, (state, action) => {
        state.userdpt_list = action.payload.data;
        state.userlist = action.payload.data.user.data;
        state.usercount = action.payload.data.user.pagination;
        state.departmentlist = action.payload.data.departments;
        state.departmentcount = action.payload.data.departments.pagination;
        state.designationlist = action.payload.data.designations;
        state.designationcount = action.payload.data.designations.pagination;
        // state.userlist=Object.entries(action.payload.user).map(([key, value]) => ({
        //         value: parseInt(key),
        //         label: value
        //     }));
        // state.deptlist=Object.entries(action.payload.departments).map(([key, value]) => ({
        //   value: parseInt(key),
        //   label: value
        //   }));
        //   state.desglist = Object.entries(action.payload.designations).map(([key, value]) => ({
        //     value: parseInt(key),
        //     label: value
        // }));
       
          
      })
      .addCase(fetchUserdeparment .rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase("persist/REHYDRATE", (state, action) => {
        // clear filter state here
        state.userdpt_list = [];
        state.error = null;
      });
  },
});

export const {
  
} = usrdptFilterSlice.actions;
export default usrdptFilterSlice.reducer;