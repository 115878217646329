import React, { useState } from 'react'
import help from '../../assets/images/help.svg';
import logo from '../../assets/images/login-logo.svg';
import layout from '../../assets/images/layout-img.svg';
import editmail from '../../assets/images/edit-mail.svg';
import '../../assets/css/style.css';
import '../../assets/css/resp.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Loader from '../../Components/Loader/Loader';
const { REACT_APP_AUTH_SERVER}= process.env;
const {REACT_APP_SERVER_URL}=process.env;
const VerifyOTP = () => {
    const location=useLocation()
    const navigate=useNavigate();
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        setValues,
        setFocus,
        values,
        reset,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const [email, setemail] = useState(location?.state?.email)
    const [loading, setloading] = useState(false)


    const handleSubmitForm = async (e) => {
      
        if (isValid) {
            setloading(true)
          await axios({
            url: REACT_APP_AUTH_SERVER + "/api/verify-otp",
            method: 'POST',
            data: {
              email: email,
              otp: getValues().otp,
            },
          }
          ).then((response) => {
            // console.log(response.data)
            if (response.data.success==true) {
         
           toast.success(response.data.message)
           setloading(false)
           navigate('/updatepassword',{state:{email:email}})
            }
            else {
                toast.error(response.data.message)
                setloading(false)
            
            }
            // window.location.reload();
          })
          .catch((err) => {
            setloading(false)
            if(err.request){ console.log(err.request) } if(err.response){ 
                console.log(err.response)
          
          }})
        }
        setloading(false)
      }
      const resendotp =async()=>{
        await axios({
            url: REACT_APP_AUTH_SERVER + "/api/reset-password",
            method: 'POST',
            data: {
              email: email,
            },
          }
          ).then((response) => {
            // console.log(response.data)
            if (response.data.success==true) {
          
           toast.success(response.data.message)
            }
            else {
                toast.error(response.data.message)
            }
            // window.location.reload();
          })
          .catch((err) => {
            if(err.request){ console.log(err.request) } if(err.response){ 
                console.log(err.response)
          
          }})
      }
  return (
    <div>
     
     <Loader loading={loading}/>
<div className="login-page">
    <div className="container-fluid">
        <div className="row ">
            <div className="col-lg-5 col-md-5 col-sm-12 p-0">
                <div className="login-slider">
                    <div className="login-logo">
                        <img src={logo} alt="Getlead"/>
                    </div>
                    <div className="layout-img">
                        <img src={layout} alt="Getlead"/>
                    </div>
                </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12 p-0">
                <div className="login-form d-flex align-items-center justify-content-center">
                    <div className="form-col">
                        <div className="help-text">
                            <a href="#"><span><img src={help} alt="Getlead"/> </span> Help center</a>
                        </div>
                        <form onSubmit={handleSubmit(handleSubmitForm)}>
                            <div className="form-header">
                                <h2>Verify your email address</h2> 
                                <p className="">We have send one time verification code to your <br/>
                                    mail ID <span  style={{fontWeight:"600"}} className="d-inline-flex">{email}&nbsp;
                                     <Link to={'/forgotpassword'}> <img  style={{width:"16px",height:"16px"}} src={editmail} alt="Getlead"/></Link></span> 
                                    </p>
                            </div>
                            <div className="form-group">
                                <label >OTP</label>
                                <input type="text" className="form-control" 
                                {...register("otp", {
                                    required: false,
                                })}/>
                                 {errors.otp?.type === "required" && (
                         <span className="error">OTP is required</span>
                        )}
                              </div>
                              <button type="submit" className="btn login-btn" style={{marginBottom:"60px"}}>Verify OTP</button>
                              <div className="otp-text">
                                <p>Didn’t get OTP? <span onClick={resendotp} style={{cursor:"pointer", color:"#3366CC"}}>Resend</span></p> 
                              </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <ToastContainer/>
    </div>
</div>



    </div>
  )
}

export default VerifyOTP