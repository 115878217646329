import React, { useState } from 'react';
import help from '../../assets/images/help.svg';
import logo from '../../assets/images/login-logo.svg';
import layout from '../../assets/images/layout-img.svg';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/style.css';
import '../../assets/css/resp.css';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../Components/Loader/Loader';
const { REACT_APP_AUTH_SERVER } = process.env;
const { REACT_APP_SERVER_URL } = process.env;
function Forgotpassword() {
    const navigate = useNavigate();
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        setValues,
        setFocus,
        values,
        reset,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const [loading, setloading] = useState(false)
    const handleSubmitForm = async (e) => {
        // e.preventDefault();

        if (isValid) {
            setloading(true)
            await axios({
                url: REACT_APP_AUTH_SERVER + "/api/reset-password",
                method: 'POST',
                data: {
                    email: getValues().email,
                },
            }
            ).then((response) => {
                // console.log(response.data)
                if (response.data.success == true) {

                    toast.success(response.data.message)
                    setloading(false)
                    navigate('/verifyOtp', { state: { email: getValues().email } })
                }
                else {
                    toast.error(response.data.data.email[0])
                    setloading(false)
                    console.log(response.data, "invalid email");
                }
                // window.location.reload();
            })
                .catch((err) => {
                    setloading(false)
                    if (err.request) { console.log(err.request) } if (err.response) {
                        console.log(err.response)

                    }
                })
        }
        setloading(false)
    }

    return (
        <div className="login-page">
            <Loader loading={loading} />
            <div className="container-fluid">
                <div className="row ">
                    <div className="col-lg-5 col-md-5 col-sm-12 p-0">
                        <div className="login-slider">
                            <div className="login-logo">
                                <img src={logo} alt="Getlead" />
                            </div>
                            <div className="layout-img">
                                <img src={layout} alt="Getlead" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-12 p-0">
                        <div className="login-form d-flex align-items-center justify-content-center">
                            <div className="form-col">
                                <div className="help-text">
                                    <a href="#"><span><img src={help} alt="Getlead" /> </span> Help center</a>
                                </div>
                                <form onSubmit={handleSubmit(handleSubmitForm)}>
                                    <div className="form-header">
                                        <h2>Forgot password?</h2>
                                        <p>Enter your Email and we will send an OTP for verification</p>
                                    </div>
                                    <div className="form-group">
                                        <label>Email address</label>
                                        <input type="email"
                                            style={{ width: "100%" }}
                                            autoComplete="off"
                                            {...register("email", {
                                                required: true,
                                                pattern:
                                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

                                            })}
                                            className="form-control"
                                            aria-describedby="emailHelp"
                                            placeholder="Enter email" />
                                        {errors.email?.type === "required" && (
                                            <span className="error">Email address / Mobile number is required</span>
                                        )}
                                        {errors.email?.type === "pattern" && (
                                            <span className="error">Email is not valid</span>
                                        )}
                                    </div>
                                    <button type="submit" className="btn login-btn" style={{ marginBottom: "40px" }}>Send OTP</button>
                                    <div className=" back-home">
                                        <Link to={'/Login'}>Back to home</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </div>
    )
}

export default Forgotpassword