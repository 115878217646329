import React from 'react'
import { useForm } from 'react-hook-form';
import calender from '../../assets/images/calender.svg'
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import Datetime from 'react-datetime'
import "react-datetime/css/react-datetime.css";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { ErrorMessage } from '@hookform/error-message';
import moment from 'moment';
const { REACT_APP_SERVER_URL } = process.env;
const UpdateTask = ({ticketid,taskid}) => {

    const token = localStorage.getItem("token")
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        control,
        setValue,
        setFocus,
        reset,
        watch,
        setError,
        clearErrors,
        values,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    useEffect(() => {
        // getcategory()
        getTaskDetail()
        // getTicket()
    }, [taskid,ticketid])
    const [catlist, setcatlist] = useState()
    const [statuslist, setstatuslist] = useState([])
    const [taskstartDate, settaskstartDate] = useState(new Date());
    const [assign, setassign] = useState([])
    const [assignedto, setassignedto] = useState([]);
    const {  asignArray } = useSelector((state) => state.metalist);
    const {  task_category } = useSelector((state) => state.taskcategory);
    const {  task_status } = useSelector((state) => state.taskstatus);
   
    const handleAssignedto = (value) => {
        setValue("assignedto", value)
        setassignedto(value)
    }
  
    const getTaskDetail=()=>{
        axios({
            url:`${REACT_APP_SERVER_URL}/api/tasks/${taskid}`,
            method:'GET',
            headers: { "Authorization": `Bearer ${token}` },
        }).then((res)=>{
            // console.log(res.data,"task");
            setValue("task",{
                taskname:res.data.data.task.task_name,
                category:res.data.data.task.category.id,
                type:Number(res.data.data.task.type_id),
                taskdes:res.data.data.task.description,
            })
            setValue("assignedto",res.data.data.task.agent?.map(user => {
                return {
                    value: parseInt(user.user_id),
                    label: user.name
                };
            }))
            setassignedto(res.data.data.task.agent?.map(user => {
                return {
                    value: parseInt(user.user_id),
                    label: user.name
                };
            }))
            const originalDateString = res.data.data.task.time;
            const originalDate = new Date(originalDateString);
    
            const formattedDate = originalDate.toString();
            settaskstartDate(formattedDate)
            // console.log(formattedDate);
        }).catch((err)=>{
            console.log(err);
        })
    }
    // console.log(taskstartDate,"kk");
    const updateTask = async () => {
        const dateObj = new Date(taskstartDate);
        const formattedDate = `${dateObj.getUTCFullYear()}-${String(dateObj.getUTCMonth() + 1).padStart(2, '0')}-${String(dateObj.getUTCDate()).padStart(2, '0')} ${String(dateObj.getUTCHours()).padStart(2, '0')}:${String(dateObj.getUTCMinutes()).padStart(2, '0')}:${String(dateObj.getUTCSeconds()).padStart(2, '0')}`;
        let assign = assignedto.map((val) => {
            return (
                parseInt(val.value)
            )
        })
    
        await axios({
            url: `${REACT_APP_SERVER_URL}/api/tasks/${taskid}`,
            method: "Put",
            headers: { "Authorization": `Bearer ${token}` },
            data: {
                ticket_id: parseInt(ticketid),
                task: getValues().task.taskname,
                type_id: parseInt(getValues().task.type),
                date_time: formattedDate,
                description: getValues().task.taskdes,
                assigned_to: assign,
                category_id:  parseInt(getValues().task.category),
            }

        }).then((res) => {

            if (res.data.success == true) {
                toast.success(res.data.message)
                // getlist()
                Closemodal()
            }
            else {
                toast.error(res.data.message)
            }
        }).catch((err) => {
        })
    }
    const Closemodal =()=>{
        const addCustomerModal = document.getElementById('updatetaskModal');
                addCustomerModal.style.display = 'none';
    }
    const minDate = moment(); // Define the minimum date

    const isValidDate = current => {
        return current.isAfter(minDate, 'day'); // Check if the selected date is after the minimum date
    };
  return (
    <>
    <div className="modal-dialog modal-dialog-lg ticket-modal customer-modal log-modal" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={Closemodal}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body pt-1">
                        <form className="add-ticket-form" onSubmit={handleSubmit(updateTask)}>
                            <h3>Update task</h3>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label for="">Task </label>
                                        <input className="form-control"
                                            {...register("task.taskname", {
                                                required: "Task is required"
                                            })}
                                            type='text' />
                                        {errors?.taskname && <span className="error"> {errors.taskname.message}</span>}

                                        {/* <select className="form-control">
                                                                        <option value="">Call</option>
                                                                        <option value="">Meeting</option>
                                                                        <option value="">Note</option>
                                                                        <option value="">Email</option>
                                                                    </select> */}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label for="">Category</label>
                                        <select className="form-control"
                                            {...register("task.category", {
                                                required: "Category is required"
                                            })}>
                                            <option value="">select</option>
                                            {task_category?.map((cat) => {
                                                return (

                                                    <option value={cat.id}>{cat.category}</option>
                                                )
                                            })}
                                        </select>
                                        {errors?.category && <span className="error"> {errors.category.message}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label for="">Status</label>
                                        <select className="form-control"
                                            {...register("task.type", {
                                                required: "Status is required"
                                            })}>
                                            <option value="">select</option>
                                            {task_status?.map((cat) => {
                                                return (

                                                    <option value={cat.id}>{cat.type}</option>
                                                )
                                            })}
                                        </select>
                                        {errors?.category && <span className="error"> {errors.category.message}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label for="">Assigned To</label>
                                        <Select isMulti
                                                {...register("assignedto", {
                                                    required: "Assigned to is required",
                                                })}
                                                name='assignedto'
                                                options={asignArray}
                                                value={assignedto}
                                                placeholder="--Select--"
                                                onChange={(value) => handleAssignedto(value)}
                                            />
                                            {assignedto.length === 0 && <ErrorMessage
                                                errors={errors}
                                                name="assignedto"
                                                render={({ message }) => <span className="error">{message}</span>}
                                            />}
                                    </div>
                                </div>
                                
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label for="">Due on</label>
                                        <div className="datepicker date input-group">
                                            {/* <input type="text" placeholder="Choose Date" className="form-control" id="fecha1" /> */}
                                            <Datetime
                                             
                                                name='date'
                                                isValidDate={isValidDate}
                                                value={taskstartDate} onChange={(date) => settaskstartDate(date)} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <img src={calender} alt="GetLead" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-6 col-md-12 col-sm-12">
                                                                <div className="form-group">
                                                                    <label for="">Time</label>
                                                                    <input type="email" className="form-control" id="" aria-describedby="" />
                                                                </div>
                                                            </div> */}
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label for="">Description</label>
                                        <textarea className="form-control"
                                            {...register("task.taskdes", {
                                                required: "Description is required"
                                            })}></textarea>
                                        {errors?.taskdes && <span className="error"> {errors.taskdes.message}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-btn">
                                        <button className="add-ticket w-100" type='submit'>Add Task</button>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <div className="form-btn">
                                        <button
                                            type='button'
                                            style={{ width: "fit-content", padding: "12px 50px" }}
                                            className="cancel-btn w-100" onClick={Closemodal}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            
        </>
  )
}

export default UpdateTask