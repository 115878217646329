import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


const {REACT_APP_SERVER_URL}=process.env;
export const fetchCustometTicketDatas  = createAsyncThunk(
  "filters/fetchCustometTicketDatas ",
  async (params) => {
    const { data } = await axios.get(
        `${REACT_APP_SERVER_URL}/api/customer?page=${params.current_page?params.current_page:1}&per_page=${8}&search=${params.search?params.search:""}`,
      {
        headers: {"Authorization" : `Bearer ${params?.token}`},
      }
    );
  
    return data.data;
  }
);

export const metaCustomerTicketSlice= createSlice({
  name: "filtercustomerticket",
  initialState: {
    cus_ticket_list: [],
    error: null,
    loading:true
  },
  
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustometTicketDatas .pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCustometTicketDatas .fulfilled, (state, action) => {
        state.cus_ticket_list = action.payload.data;
        state.totalRecords = action.payload.pagination;
        state.loading = false;
          
      })
      .addCase(fetchCustometTicketDatas .rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase("persist/REHYDRATE", (state, action) => {
        state.cus_ticket_list = [];
        state.error = null;
        state.loading = false;
      });
  },
});

export const {
  
} = metaCustomerTicketSlice.actions;
export default metaCustomerTicketSlice.reducer;